import gql from "graphql-tag";

export const getWaybillDataQuery = gql`
  query GetWaybillData($routeId: ID!, $orderInfoComposite: String!) {
    getWaybillData(routeId: $routeId, orderInfoComposite: $orderInfoComposite) {
      routeId
      orderInfoComposite
      customerNumber
      orderId
      driverSignature
      driverNameClarification
      driverTimestamp
      senderSignature
      senderNameClarification
      senderTimestamp
      receiverSignature
      receiverNameClarification
      receiverTimestamp
      senderSignatureNotAvailable
      receiverSignatureNotAvailable
      contractorId
      actualPickupStartTime
      actualPickupEndTime
      actualUnloadStartTime
      actualUnloadEndTime
      TTL
    }
  }
`;

