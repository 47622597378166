import gql from "graphql-tag";

export const generateUploadUrlQuery = gql`
  query generateUplaodUrl(
    $routeId: Int!
    $orderId: Int!
    $waybillNum: String!
    $imageId: Int!
    $height: Int
    $width: Int
  ) {
    generateUploadUrl(
      waybillImageInput: {
        routeId: $routeId
        orderId: $orderId
        waybillNum: $waybillNum
        imageId: $imageId
        height: $height
        width: $width
      }
    ) {
      url
    }
  }
`;
