
export const uploadWaybillImageToS3 = async (url, file) => {
  try {
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "image/jpeg",
      },
      body: file,
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error('Error uploading waybill image:', error);
    throw error;
  }
}

/**
 * Get file from S3 with presigned URL
 * @param {string} url
 * @returns {Promise<string>} file data as base64 string
 */
export const getFileFromS3 = async (url) => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const blob = await response.blob();
    const reader = new FileReader();
    const base64String = await new Promise((resolve, reject) => {
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
    return base64String;
  } catch (error) {
    console.error('Error getting file from S3:', error);
    throw error;
  }
}
