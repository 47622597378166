import React from "react";
import PropTypes from "prop-types";

import Query from "../../apollo/extensions/CachedQuery"

import uniq from "lodash/uniq";

import * as Sentry from '@sentry/browser';

import getUniqueProps from "../../utils/getUniqueProps";
import { getOrderQuery } from "../../api/graphql/getOrder";

import InvalidOrders from "../../components/routes/destination/InvalidOrders";
import Loading from "../../components/layout/Loading";
import Error from "../../components/layout/Error";
import DestinationOrder from "../../components/routes/destination/DestinationOrder";

const InvalidLoad = ({ invalidTransactions, destination, onClose, companyName }) => {
  if (invalidTransactions.length < 1) return null;

  const destinationTransactions = destination.transactions;
  const destinationTransactionIds = getUniqueProps(
    destinationTransactions,
    "transactionId"
  );
  const invalidOrders = getUniqueProps(invalidTransactions, "orderNum");

  return (
    <InvalidOrders numOrders={invalidOrders.length} onClose={onClose}>
      {invalidOrders.sort().map(orderNum => (
        <Query
          key={orderNum}
          query={getOrderQuery}
          variables={{ orderId: `${orderNum}` }}
        >
          {({ loading, cached, error, data }) => {
            if (loading && !cached) return <Loading />;
            if(error) {
              Sentry.captureEvent({
                message: "Query error - InvalidLoad",
                extra: {
                  'error': error,
                  'data': data,
                  'variableOrderId': orderNum
                },
              });          
            }
            if (error) return <Error error={error} />;

            const order = data.getOrder;

            if (!order) return null;

            const commodities = [];
            const unloadLocations = [];

            order.rows.forEach(row =>
              row.transactions.forEach(transaction => {
                // is this transaction a match for the destination to be shown?
                if (
                  destinationTransactionIds.includes(
                    transaction.pickupTransactionId
                  ) ||
                  destinationTransactionIds.includes(
                    transaction.unloadTransactionId
                  )
                ) {
                  // yep, add the commodities and unloadlocations to the list to be shown for this order
                  commodities.push({
                    name: row.commodityName,
                    amount: row.orderedAmount,
                    unit: row.orderedUnit
                  });
                  unloadLocations.push(
                    `${transaction.unloadLocation}, ${transaction.unloadCity}`
                  );
                }
              })
            );
            return (
              <DestinationOrder
                orderNum={orderNum}
                customerOrderNum={order.customerOrderNum}
                commodities={uniq(commodities)}
                unloadLocations={uniq(unloadLocations)}
                additionalInfo={order.additionalInfo}
                companyName={companyName}
              />
            );
          }}
        </Query>
      ))}
    </InvalidOrders>
  );
};

InvalidLoad.propTypes = {
  destination: PropTypes.shape({
    transactions: PropTypes.arrayOf(
      PropTypes.shape({
        transactionId: PropTypes.string.isRequired
      })
    ).isRequired
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  invalidTransactions: PropTypes.array,
  companyName: PropTypes.string.isRequired,
};

InvalidLoad.defaultPropTypes = {
  invalidTransactions: []
};

export default InvalidLoad;
