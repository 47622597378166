import { openDB } from 'idb';

const dbPromise = openDB('rahtari', 1, {
  upgrade(db) {
    db.createObjectStore('waybillImages');
  },
});

// TODO - deprecate away this whole db stuff
export const removeAllImages = async () => {
  const db = await dbPromise;
  return db.clear('waybillImages');
}
