import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import SignaturePad from 'react-signature-canvas';

import Button from '../../../input/Button';
import styles from './WaybillSignature.module.scss';

const WaybillSignature = ({ presetSignature, setSignatureData }) => {
  const sigPad = useRef({});

  useEffect(() => {
    sigPad.current.clear();
    if (presetSignature) {
      sigPad.current.fromDataURL(presetSignature);
      setSignatureData(presetSignature, Moment().format('DD.MM.YYYY HH:mm'));
    }
  }, [presetSignature]);

  const clear = () => {
    sigPad.current.clear();
    setSignatureData('', '');
  };

  const setSignature = () => {
    const signatureDataURL = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    const timestamp = Moment().format('DD.MM.YYYY HH:mm');

    setSignatureData(signatureDataURL, timestamp);
  };

  return (
    <div className={styles.container}>
      <div className={styles.sigContainer}>
        <SignaturePad
          canvasProps={{ className: styles.sigPad }}
          clearOnResize={false}
          ref={sigPad}
          onEnd={setSignature}
        />
      </div>
      <div>
        <Button
          className={styles.emptyButton}
          onClick={clear}
        >
          Tyhjennä
        </Button>
      </div>
    </div>
  );
};

WaybillSignature.propTypes = {
  presetSignature: PropTypes.string,
  setSignatureData: PropTypes.func.isRequired
};

export default WaybillSignature;
