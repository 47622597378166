import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { withApollo } from "react-apollo";
import { styled } from '@mui/material/styles';
import { Button as MuiButton, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import Modal from 'react-responsive-modal';

import "./WaybillPhotoCapture.css";

import { getFileFromS3, uploadWaybillImageToS3 } from '../../../api/api';
import { setUploading } from '../../../reducers/waybillImagesSlice';
import { deleteWaybillImageMutation } from '../../../api/graphql/deleteWaybillImage';
import { generateUploadUrlQuery } from '../../../api/graphql/generateUploadUrl';
import { getImageDownloadUrlQuery } from '../../../api/graphql/getImageDownloadUrl';
import Button from "../../input/Button";
import Spinner from '../../layout/Spinner';

const IMAGE_COMPRESSION_QUALITY = 0.5; // 0.0 - 1.0 (1.0 = 100% quality)

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const getPresignedUploadUrl = async (appSyncClient, routeId, orderId, waybillNum, imageId, height, width) => {
  try {
    const response = await appSyncClient.query({
      query: generateUploadUrlQuery,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
        height,
        width,
      },
      fetchPolicy: 'no-cache'
    });
    return response.data.generateUploadUrl;
  } catch (error) {
    console.log('Query error on generateUploadUrl', JSON.stringify(error, null, 2));
  }
}

const getDownloadUrl = async (appSyncClient, routeId, orderId, waybillNum, imageId) => {
  try {
    const response = await appSyncClient.query({
      query: getImageDownloadUrlQuery,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
      },
      fetchPolicy: 'network-only'
    });
    
    return response.data.getImageDownloadUrl.url;
  } catch (error) {
    console.log('Query error on getImageDownloadUrl', JSON.stringify(error, null, 2));
  }
}

const deleteWaybillImage = async (appSyncClient, routeId, orderId, waybillNum, imageId) => {
  console.log('deleting image', routeId, orderId, waybillNum, imageId);
  try {
    const response = await appSyncClient.mutate({
      mutation: deleteWaybillImageMutation,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
      },
      fetchPolicy: 'no-cache'
    });

    // after deleting from S3, update the cache to reflect the deletion
    appSyncClient.writeQuery({
      query: getImageDownloadUrlQuery,
      variables: {
        routeId,
        orderId,
        waybillNum,
        imageId,
      },
      data: {
        getImageDownloadUrl: {
          url: null,
          __typename: 'WaybillImageUrl',
        },
      },
    });

    return response.data.deleteWaybillImage;
  } catch (error) {
    console.log('Mutation error on deleteWaybillImage', JSON.stringify(error, null, 2));
  }
}

const getImageStatusIcon = (status) => {
  if (LOADING_STATES.includes(status)) {
    return <Spinner small={true} />;
  } else if (status === 'done') {
    return <CloudDoneIcon sx={{ color: "#2479b3" }} />;
  } else {
    return null;
  }
};

const compressImage = async (dataSrc, contentType) => {
  const data = await new Promise(async (resolve, _) => {
    const img = document.createElement('img');
    img.src = dataSrc + '';

    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      ctx.drawImage(img, 0, 0, img.width, img.height);
      const dataURI = canvas.toDataURL(contentType, IMAGE_COMPRESSION_QUALITY);
      resolve({
        dataUri: dataURI,
        width: canvas.width,
        height: canvas.height,
      });
    };

    img.onerror = (error) => {
      console.error('Error loading image:', error);
      resolve(dataSrc);
    }
  });
  return { imageUri: data.dataUri };
};

const getSrcFromFile = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        resolve({
          width: img.width,
          height: img.height,
          src: e.target.result,
        });
      };
      img.src = e.target.result;
      img.onerror = reject;
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
}

const getLoadingMessageForStatus = (status) => {
  switch (status) {
    case 'loading':
      return 'Tietoja haetaan...';
    case 'downloading':
      return 'Ladataan kuvaa...';
    case 'uploading':
      return 'Lähetetään kuvaa...';
    case 'deleting':
      return 'Poistetaan kuvaa...';
    default:
      return '';
  }
}

const getErrorMessageForStatus = (status) => {
  switch (status) {
    case 'error':
      return 'Kuvan tilan tarkistaminen palvelimelta epäonnistui'
    case 'errorDownloading':
      return 'Kuvan lataaminen palvelimelta epäonnistui'
    case 'errorUploading':
      return 'Kuvan tallennus palvelimelle epäonnistui'
    case 'errorDeleting':
      return 'Kuvan poistaminen palvelimelta epäonnistui'
    default:
      return 'Jokin meni pieleen! Jos ongelma jatkuu, ota yhteyttä ylläpitoon'
  }
}

const getErrorModalContents = (status) => (
  <div>
    <br/>
    <span>{getErrorMessageForStatus(status)}</span>
  </div>
)

const LOADING_STATES = ['loading', 'downloading', 'uploading', 'deleting'];
const ERROR_STATES = ['error', 'errorDownloading', 'errorUploading', 'errorDeleting'];

function WaybillPhotoUpload(props) {
  const {
    client,
    isOnline,
    orderNum,
    routeId,
    waybillNumber,
    imageIndex,
  } = props;
  const dispatch = useDispatch();

  /**
   * imageStatus can be one of the following:
   * loading          - getting presigned url for download
   * downloading      - image is being fetched from S3
   * uploading        - image is being sent to S3
   * deleting         - image is being deleted from S3
   * done             - image loaded and should be visible
   * error            - could not check status from server if image exists
   * errorDownloading - could not download image from S3
   * errorUploading   - could not upload image to S3
   * errorDeleting    - could not delete image from S3
   * null             - no image to show / initial state
   */
  const [imageStatus, setImageStatus] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const handleImageStatusChange = (status) => {
    setImageStatus(status);
    if (ERROR_STATES.includes(status)) {
      setErrorModalOpen(true);
    }
    if (LOADING_STATES.includes(status)) {
      dispatch(setUploading({ uploading: true }));
    } else {
      dispatch(setUploading({ uploading: false }));
    }
  }

  useEffect(() => {
    const fetchImage = async () => {
      let downloadUrl;
      try {
        downloadUrl = await getDownloadUrl(client, routeId, orderNum, waybillNumber, imageIndex);
        if (!downloadUrl) {
          handleImageStatusChange(null);
          return;
        }
      } catch (error) {
        handleImageStatusChange('error');
        return;
      }
      
      try {
        handleImageStatusChange('downloading');
        const storedImage = await getFileFromS3(downloadUrl);
        if (storedImage) {
          setImgSrc(storedImage);
          handleImageStatusChange('done');
        }
      } catch (error) {
        handleImageStatusChange('errorDownloading');
      }
    };

    if (isOnline && waybillNumber && orderNum !== null && !imgSrc) {
      handleImageStatusChange('loading');
      fetchImage();
    }
  }, [isOnline, waybillNumber, orderNum, routeId]);

  const handleImgChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }
    try {
      const imgInfo = await getSrcFromFile(file);
      setImgSrc(imgInfo.src);
      const metadata = {
        width: imgInfo.width,
        height: imgInfo.height,
      }

      handleImageStatusChange('uploading');

      const uploadUrl = await getPresignedUploadUrl(
        client,
        parseInt(routeId, 10),
        orderNum,
        waybillNumber,
        imageIndex,
        metadata.height,
        metadata.width
      );

      // create new file with compressed image
      const contentType = file.type;
      let finalFile = file;
      if (contentType === 'image/png') {
        // Compression is not supported for PNG
      } else {
        const compressedImgSrc = await compressImage(imgInfo.src, contentType);
        finalFile = await fetch(compressedImgSrc.imageUri).then((r) => r.blob());
      }

      const res = await uploadWaybillImageToS3(uploadUrl.url, finalFile);
      if (res.ok) {
        handleImageStatusChange('done');
      } else {
        console.error('Error uploading waybill image:', res);
        handleUplaodError();
      }
    } catch (error) {
      console.error('Error uploading waybill image:', error);
      handleUplaodError();
    }
  }

  const handleUplaodError = () => {
    handleImageStatusChange('errorUploading');
    setImgSrc(null);
  }

  const handleImgDelete = async () => {
    handleImageStatusChange('deleting');
    try {
      const deleteFromS3 = await deleteWaybillImage(client, routeId, orderNum, waybillNumber, imageIndex);
      if (deleteFromS3) {
        setImgSrc(null);
        handleImageStatusChange(null);
      }
    } catch (error) {
      handleImageStatusChange('errorDeleting');
      console.error('Error deleting waybill image:', error);
    }
  }

  const handleModalClose = () => {
    setErrorModalOpen(false);
    handleImageStatusChange(null);
  }

  const loading = LOADING_STATES.includes(imageStatus);

  return (
    <>
      {errorModalOpen && 
        <Modal open={errorModalOpen} onClose={() => handleModalClose()}>
          {getErrorModalContents(imageStatus)}
          <Button onClick={() => handleModalClose()}>
            Sulje
          </Button>
        </Modal>
      }
      <div key={imageIndex} className="container">
        <h3 className="imageHeader">Kuva {imageIndex}</h3>
        {!imgSrc ? (
          <MuiButton
            component="label"
            variant="outlined"
            tabIndex={-1}
            startIcon={loading ? <Spinner small={true} /> : <AddAPhotoIcon sx={{ width: "60px", height: "60px"}}/>}
            sx={{
              width: "300px",
              height: "360px",
              color: "rgba(0,0,0,.6)",
              border: "2px solid rgba(0,0,0,.3)",
              borderRadius: "8px",
            }}
          >
            {loading && getLoadingMessageForStatus(imageStatus)}
            <VisuallyHiddenInput
              type="file"
              accept="image/png, image/jpeg"
              onChange={(event) => handleImgChange(event)}
            />
          </MuiButton>
        ) : (
          <div className="imageContainer">
            <img
              src={imgSrc}
              height={"auto"}
              width={"auto"}
              style={{ maxHeight: "360px", maxWidth: "300px", verticalAlign: "middle", display: "block" }}
              alt={`image${imageIndex}`}
              />
          </div>
        )}
        <div className={`statusContainer${imgSrc ? '' : 'Hidden'}`}>
          <div>
            <MuiButton
              component="label"
              startIcon={<AddAPhotoIcon />}
              sx={{
                color: "rgba(0,0,0,.6)",
              }}
            >
              <VisuallyHiddenInput
                type="file"
                accept="image/png, image/jpeg"
                onChange={(event) => handleImgChange(event)}
                onClick={(event) => {event.target.value = null}}
              />
            </MuiButton>
            <IconButton
              onClick={() => handleImgDelete()}>
              <DeleteIcon />
            </IconButton>
          </div>
          {getImageStatusIcon(imageStatus)}
        </div>
      </div>
    </>
  );
}

export default withApollo(WaybillPhotoUpload);
