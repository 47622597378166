import React from "react";
import PropTypes from "prop-types";
import { Mutation, withApollo } from "react-apollo";
import AWSAppSyncClient from "aws-appsync";

import {
  adminAddUserMutation,
  adminAddUserProps
} from "../../api/graphql/adminAddUser";
import { addDriverMutation, addDriverProps } from "../../api/graphql/addDriver";
import {
  getUsernameAvailabilityProps,
  getUsernameAvailability
} from "../../api/graphql/getUsernameAvailability";

import CurrentUser from "../data/CurrentUser";
import AddDriverForm from "../../components/usermanagement/AddDriverForm";
import Modal from "../../components/layout/Modal";
import AddDriverConfirmation from "../../components/usermanagement/AddDriverConfirmation";

class AddDriver extends React.Component {
  checkUsernameAvailability = async username => {
    const { client } = this.props;
    const response = await client.query({
      query: getUsernameAvailability,
      variables: { username }
    });

    return getUsernameAvailabilityProps(response);
  };
  

  render() {
    return (
      <CurrentUser>
        {currentUser => {
          const { isAdmin } = currentUser;
          const addMutation = isAdmin
            ? adminAddUserMutation
            : addDriverMutation;
          const driverPropsResolver = isAdmin
            ? adminAddUserProps
            : addDriverProps;
          //const usersQuery = isAdmin ? adminGetUsersQuery : getUsersQuery;

          return (
            <Mutation mutation={addMutation}>
              {(addDriver, { data }) => {
                const driver = data ? driverPropsResolver(data) : null;
                const uncloseableModal = !!driver;

                return (
                  <Modal uncloseable={uncloseableModal}>
                    <h3>Uusi {isAdmin ? "käyttäjä" : "kuljettaja"}</h3>
                    {!driver ? (
                      <AddDriverForm
                        checkUsernameAvailability={
                          this.checkUsernameAvailability
                        }
                        isAdmin={isAdmin}
                        onSubmit={userInfo => {
                          addDriver({
                            variables: {
                              username: userInfo.username,
                              name: userInfo.name,
                              email: userInfo.email,
                              phone_number: userInfo.phone,
                              contractor_id: userInfo.contractorId
                            }
                          });
                        }}
                      />
                    ) : (
                      <AddDriverConfirmation
                        {...driver}
                        firstname={driver.name.split(" ")[0]}
                      />
                    )}
                  </Modal>
                );
              }}
            </Mutation>
          );
        }}
      </CurrentUser>
    );
  }
}

AddDriver.propTypes = {
  client: PropTypes.instanceOf(AWSAppSyncClient).isRequired
};

export default withApollo(AddDriver);
