import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import 'react-responsive-modal/styles.css';
import { Modal } from 'react-responsive-modal';
import { navigate } from "@reach/router";
import { ApolloConsumer, withApollo } from "react-apollo";

import getTimeString from "../../../utils/getTimeString";
import styles from "./DestinationFooter.module.scss";
import Button from "../../input/Button";
import LogExceptionButton from "../../exceptions/LogExceptionButton";
import WaybillSignatureModal from "../order/waybillSignature/WaybillSignatureModal";
import { WaybillDataShape } from "../order/shapes";
import { getWaybillPdfQuery } from '../../../api/graphql/getWaybillPdf';
import { hasWaybillImagesQuery } from "../../../api/graphql/hasWaybillImages";

import updateTransactionInput from "../../../utils/updateTransactionInput";
import fetchDestinationOrderCustomer from "../../../utils/fetchDestinationOrderCustomer";
import { isWaybillSigned, isSet } from '../utils/waybills';
import sendMessageToNativeApp from "../../../utils/sendMessageToNativeApp";

import { getFileFromS3 } from "../../../api/api";
import { getOrderQuery } from "../../../api/graphql/getOrder";
import { logEvent } from "../../../api/graphql/logEvent";
import getUniqueProps from "../../../utils/getUniqueProps";
import FinalSubmitModal from "./FinalSubmitModal";

const logToBackend = async (appSyncClient, message) => {
  try {
    await appSyncClient.mutate({
      mutation: logEvent,
      variables: {
        message: message
      },
    });
  } catch (error) {
    console.log("Error logging", error);
  }
}

const getWaybillPdf = async (appSyncClient, orderData) => {
  console.log('getWaybillPdf, orderData:', JSON.stringify(orderData, null, 2));

  try {
    const response = await appSyncClient.query({
      query: getWaybillPdfQuery,
      variables: { orderData: JSON.stringify(orderData) },
      fetchPolicy: 'no-cache'
    })
    console.log('getWaybillPdf response', JSON.stringify(response, null, 2));
    return response.data.getWaybillPdf;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const hasImagesUploaded = async (appsyncClient, routeId, orderId, waybillNum) => {
  try {
    const response = await appsyncClient.query({
      query: hasWaybillImagesQuery,
      variables: { routeId: parseInt(routeId), orderId: parseInt(orderId), waybillNum },
      fetchPolicy: 'network-only'
    })
    return response.data.hasWaybillImages;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const handleSignatures = (thisSignature, signaturesList, type, transactions, setSigned) => {
  const signed = thisSignature ? isWaybillSigned(thisSignature, type) : false;
  
  setSigned(signed);

  for (const signatures of signaturesList) {
    // In multiple-order case, we need to filter signatures by order or waybill. Signatures input value may be null.
    if (signatures) {
      if (transactions && type === 'load') {
        const driverSignatureIsSet = isSet(signatures?.driverSignature);
        const senderSignatureIsSet = isSet(signatures?.senderSignature) || (isSet(signatures?.senderSignatureNotAvailable) && signatures.senderSignatureNotAvailable);
        transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction =>
          updateTransactionInput(transaction.pickupTransactionId, {
            "senderNameClarification": signatures?.senderNameClarification,
            "senderTimestamp": signatures?.senderTimestamp,
            "senderSignatureNotAvailable": signatures?.senderSignatureNotAvailable,
            "driverNameClarification": signatures?.driverNameClarification,
            "driverTimestamp": signatures?.driverTimestamp,
            "signedOnLoad": driverSignatureIsSet && senderSignatureIsSet,
          }));
      } else if (transactions && type === 'unload') {
        const receiverSignatureIsSet = isSet(signatures?.receiverSignature) || (isSet(signatures?.receiverSignatureNotAvailable) && signatures.receiverSignatureNotAvailable);
        transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction =>
          updateTransactionInput(transaction.unloadTransactionId, {
            "receiverNameClarification": signatures?.receiverNameClarification,
            "receiverTimestamp": signatures?.receiverTimestamp,
            "receiverSignatureNotAvailable": signatures?.receiverSignatureNotAvailable,
            "signedOnUnload": receiverSignatureIsSet,
          }));
      }
    }
  }
}

const getElapsed = (timestamp) => {
  if (!timestamp) return null;

  const hours = moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "hours");
  const minutes = moment().diff(moment(timestamp, "DD-MM-YYYY HH:mm"), "minutes") % 60;

  return `${hours} h ${minutes} min`;
}

const base64ToBlob = (base64, mimeType) => {
  const byteCharacters = atob(base64.split(',')[1]);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

const openPdfInNewTab = (base64String) => {
  if (!base64String) {
    console.log('PDF data not found');
    return;
  }

  if (typeof base64String !== 'string') {
    console.error('Invalid base64 string', base64String);
    return;
  }

  if (!base64String.startsWith('data:application/pdf;base64,')) {
    base64String = 'data:application/pdf;base64,' + base64String;
  }

  const isNativeApp = !!window.isNativeApp && window.ReactNativeWebView;
  if (isNativeApp) {
    sendMessageToNativeApp({
      operation: 'openPdf',
      data: {
        pdfContent: base64String
      }
    });
  } else {
    const pdfBlob = base64ToBlob(base64String, 'application/pdf');
    const pdfUrl = URL.createObjectURL(pdfBlob);
    window.open(pdfUrl, '_blank');
  }
}

const createOrderData = async (waybillData, destinationId, currentVehicle, preview, downloadIfExists = false) => {
  return {
    downloadIfExists: downloadIfExists,
    preview: preview,
    customerData: waybillData.customerData,
    contractorData: waybillData.contractorData,
    payer: {
      id: waybillData.payerNum,
      name: waybillData.payerName,
      address: {
        streetAddress: waybillData.rows[0].transactions[0].unloadAddress,
        postCode: waybillData.rows[0].transactions[0].unloadZipcode,
        city: waybillData.rows[0].transactions[0].unloadCity,
      },
      contactPerson: waybillData.rows[0].transactions[0].unloadPerson,
      phoneNumber: waybillData.rows[0].transactions[0].unloadPhone,
      emailAddress: waybillData.rows[0].transactions[0].unloadEmail,
    },
    rawSisuOrder: {
      orderNum: waybillData.orderId,
      ...waybillData
    },
    licenseNum: currentVehicle?.licenseNum ?? "",
    operatorName: currentVehicle?.contractorName ?? "",
    destination: fetchDestinationOrderCustomer(destinationId)
  };
}

const DestinationFooter = ({
  ata,
  atd,
  eta,
  etd,
  orderState,
  type,
  allowActions,
  allowExceptions,
  currentVehicle,
  client, // comes from withApollo HOC
  destinationId,
  checkIfAbleToFinish,
  onAction,
  orderView, // boolean, indicates if we are in order view -> where we can ask for signatures and download waybill pdf
  relatedOrders,
  waybillData,
  actualPickupStartTime,
  actualPickupEndTime,
  actualUnloadStartTime,
  actualUnloadEndTime,
  waybillNumber,
  signed,
  setSigned,
  finalSubmitOngoing,
  setFinalSubmitOngoing,
  fetching,
}) => {
  const [actionButtonHidden, setActionButtonHidden] = useState(true);
  const [actionButtonDisabled, setActionButtonDisabled] = useState(false);
  const [openUnsentWaybillPhotosWarning, setOpenUnsentWaybillPhotosWarning] = useState(false);
  const [ordersWithMissingImages, setOrdersWithMissingImages] = useState([]);

  const waybillImagesUploading = useSelector((state) => state.waybillImages.uploading);

  useEffect(() => {
    if (!fetching) {
      console.log('DestinationFooter useEffect', orderState, signed, waybillNumber);
      resolveActionButtonDisabled();
    }
  }, [fetching, orderState, signed, waybillNumber]);

  const resolveActionButtonDisabled = async () => {
    console.log(`resolveActionButtonDisabled(), type: ${type}, state: ${orderState}, signed: ${signed}, waybillNumber: ${waybillNumber}, submitting: ${finalSubmitOngoing}`);

    switch (orderState) {
      case "new":
        break;
      case "updated":
        break;
      case "cancelled":
        break;
      case "completed":
        setActionButtonHidden(signed);
        break;
      case "ongoing":
        if (type === 'unload' && !orderView) {
          // Allow unload ending only in order-level
          setActionButtonHidden(true);
          break;
        }
        if (!signed) {
          console.warn('Allekirjoitukset puuttuvat');
        }
        setActionButtonHidden(!signed);
        break;
      default:
        // state is null, when load/unload hasn't been started yet
        if (type === 'unload') {
          // User should not be able to start unloading if loading has not finished.
          // The problem is just that we are down the wrong tree, different destination,
          // and have to fetch information about the pickuptransactions for the same order(s).
          // Check this from transactions array because this.props.orderNum is
          // only set in detailed single order view. It would be null in destination view.
          // Also waybillData would be null.
          const promises = [];
          let orderNums = []
          if (orderView) {
            orderNums.push(waybillData.orderId)
          }
          else {
            orderNums = getUniqueProps(waybillData.transactions, "orderNum").filter(num => num !== undefined); // filtering !!num did not work???
          }
          try {
            orderNums.forEach((orderNum) => {
              const orderQuery = client.query({
                query: getOrderQuery,
                variables: { orderId: orderNum },
                fetchPolicy: 'network-only'
              });
              promises.push(orderQuery);
            });
          } catch (error) {
            console.error('Error prefetching order', JSON.stringify(error, null, 2));
          }
          Promise.all(promises).then(values => {
            const flags = values.map(response => {
              const orderData = response.data.getOrder;
              if (!orderData || !Array.isArray(orderData.rows) || !orderData.rows.length) {
                return false;
              }
              console.log('Rows', orderData.rows);
              if (!Array.isArray(orderData.rows[0].transactions) || !orderData.rows[0].transactions.length) {
                return false;
              }
              return orderData.rows[0].transactions[0].actualPickupEndTime !== null ?? false;
            });

            const isAllLoaded = flags.every(value => value);
            if (!isAllLoaded) {
              console.warn('Purkua ei voi aloittaa ennen kuin lastaus on päättynyt kaikille tilauksille:', orderNums);
            }
            setActionButtonHidden(!isAllLoaded);
          });
        } else {
          setActionButtonHidden(false);
        }
        break;
    }
  }

  const onOpenUnsentWaybillPhotosModal = () => {
    // only open when unloading
    waybillData?.isOngoingUnload === true && setOpenUnsentWaybillPhotosWarning(true);
  };

  const onCloseUnsetWaybillPhotosModal = () => {
    setOpenUnsentWaybillPhotosWarning(false);
    setOrdersWithMissingImages([]);
  };

  const needToSendWaybillPhotos = async () => {
    if (!waybillData?.isOngoingUnload) {
      return false;
    }

    const destinations = fetchDestinationOrderCustomer(destinationId);

    let ordersWithMissingImages = await Promise.all(
      relatedOrders.map(async (orderId) => {
        const destination = destinations.find(dest => dest.orderNum === orderId);
        const waybillNum = destination?.waybillNumber;
  
        if (!waybillNum) {
          return orderId;
        }
  
        const hasImages = await hasImagesUploaded(client, waybillData.routeId, orderId, waybillNum);
        return hasImages ? null : orderId;
      })
    )
    
    ordersWithMissingImages = ordersWithMissingImages.filter(orderId => orderId !== null);

    // set the orders with missing images to state before returning
    setOrdersWithMissingImages(ordersWithMissingImages);

    return ordersWithMissingImages.length > 0;
  };

  const elapsed = getElapsed(ata);
  const etaDate = moment(eta, "DD-MM-YYYY HH:mm");
  const etaString = getTimeString(etaDate);
  const etdDate = moment(etd, "DD-MM-YYYY HH:mm");
  const etdString = getTimeString(etdDate);
  const ataDate = moment(ata, "DD-MM-YYYY HH:mm");
  const ataString = getTimeString(ataDate);
  const atdDate = moment(atd, "DD-MM-YYYY HH:mm");
  const atdString = getTimeString(atdDate);

  const handleSubmit = async () => {
    setFinalSubmitOngoing(true);
    const isAbleToFinish = checkIfAbleToFinish();
    if (!isAbleToFinish) {
      setFinalSubmitOngoing(false);
      return;
    }
    onAction();

    const orderDataFinal = await createOrderData(waybillData, destinationId, currentVehicle, false, false);
    await logToBackend(client, `orderNum: ${waybillData?.orderId}, about to create final PDF from DestinationFooter, waybillNumber: ${waybillNumber}`);
    await getWaybillPdf(client, orderDataFinal);
    setFinalSubmitOngoing(false);
    navigate(`/routes/schedule/upcoming/destination/${destinationId}`);
  }

  return (
    <footer className={`${styles.footer} ${styles[orderState]}`}>
      <FinalSubmitModal showModal={finalSubmitOngoing} />
      <Modal open={openUnsentWaybillPhotosWarning} onClose={onCloseUnsetWaybillPhotosModal}>
        <div className={styles.state}>
          <span>Kuljetusasiakirjakuvat</span>
        </div>
        <div>
          <p>Seuraavilta tilauksilta puuttuvat kuljetusasiakirjakuvat:</p>
          <ul>
            {ordersWithMissingImages.map((orderId, index) => (
              <li key={index}>{orderId}</li>
            ))}
          </ul>
        </div>
        <div className={styles.confirmation}>
          <p>Jatketaanko lähettämättä kuljetusasiakirjakuvia?</p>
          <Button disabled={!signed} onClick={async () => {
            setOpenUnsentWaybillPhotosWarning(false);
            await handleSubmit();
            await logToBackend(client, `orderNum: ${waybillData?.orderId}, Waybill was saved with no images, waybillNumber: ${waybillNumber}`);
          }}>
            Kyllä
          </Button>
          <Button onClick={() => setOpenUnsentWaybillPhotosWarning(false)}>Ei</Button>
        </div>
      </Modal>
      {orderState === "ongoing" ? (
        <span className={styles.time}>
          <span>
            <span className={styles.label}>Aikaa mennyt</span>
            {elapsed}
          </span>
        </span>
      ) : orderState === "completed" ? (
        <span className={styles.time}>
          <span>
            <span className={styles.label}>
              {type === "load" ? "Lastattu" : "Purettu"} {atdDate.format("D.M.YYYY")}
            </span>
            {`${ataString} –  ${atdString}`}
          </span>
          <span>
            <span className={styles.label}>{type === "load" ? "Lastauksen kesto" : "Purun kesto"}</span>
            {`${atdDate.diff(moment(ataDate, "DD-MM-YYYY HH:mm"), "hours")} h ${atdDate.diff(moment(ataDate, "DD-MM-YYYY HH:mm"), "minutes") % 60
              } min`}
          </span>
        </span>
      ) : (
        <span className={styles.time}>{`${etaString}${eta !== etd ? ` –  ${etdString}` : ""}`}</span>
      )}
      <div className={styles.buttonContainer}>
        <span className={styles.label}>
          {type === "unload" && orderState === "ongoing"
            ? "HUOM! Allekirjoita ja ota kuljetusasiakirjakuvat ennen purun lopetusta"
            : ""}
        </span>
        {/* Waybill pdf is possible to download only in orderview */}
        {orderView && (
          <Button onClick={async () => {
            const orderData = await createOrderData(waybillData, destinationId, currentVehicle, true, true);
            const downloadUrl = await getWaybillPdf(client, orderData);
            const pdfBuffer = await getFileFromS3(downloadUrl);
            openPdfInNewTab(pdfBuffer);
          }}>
            Rahtikirja
          </Button>
        )}
        {orderState === "ongoing" && orderView && (
          <ApolloConsumer>
            {(apolloClient) => (
              <WaybillSignatureModal
                apolloClient={apolloClient}
                signed={signed}
                onSignaturesChange={(thisSignature, signaturesList, type, transactions) => handleSignatures(thisSignature, signaturesList, type, transactions, setSigned)}
                type={type}
                waybillData={waybillData}
                relatedOrders={relatedOrders}
                actualPickupStartTime={actualPickupStartTime}
                actualPickupEndTime={actualPickupEndTime}
                actualUnloadStartTime={actualUnloadStartTime}
                actualUnloadEndTime={actualUnloadEndTime}
              />
            )}
          </ApolloConsumer>
        )}
        {/* logging exceptions not allowed after signing */}
        {allowExceptions && orderState && (
          <LogExceptionButton
            disabled={signed}
            onClick={() => {
              navigate(`/routes/exception/${destinationId}`);
            }}
          >
            Poikkeama
          </LogExceptionButton>
        )}
        {allowActions && orderState !== "completed" && (
          <Button
            onClick={async () => {
              setActionButtonDisabled(true);
              await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}, type: ${type}, state: ${orderState}. unload/load button onClick`);
              if (type === "unload" && orderState === "ongoing") {
                if (await needToSendWaybillPhotos()) {
                  await logToBackend(client, `orderNum: ${waybillData?.orderId}, waybillNumber: ${waybillNumber}. unload: needToSendWaybillPhotos`);
                  onOpenUnsentWaybillPhotosModal();
                } else {
                  await handleSubmit();
                }
              } else {
                onAction();
              }
              setActionButtonDisabled(false);
            }}
            hidden={actionButtonHidden}
            disabled={actionButtonDisabled || (orderState === "ongoing" && (!waybillNumber || signed === false || waybillImagesUploading))}>
              {orderState === "ongoing"
                ? type === "load"
                  ? "Lopeta lastaus"
                  : "Lopeta purku"
                : type === "load"
                  ? "Aloita lastaus"
                  : "Aloita purku"}
          </Button>
        )}
      </div>
    </footer>
  );
}

DestinationFooter.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  currentVehicle: PropTypes.object,
  ata: PropTypes.string,
  atd: PropTypes.string,
  eta: PropTypes.string.isRequired,
  etd: PropTypes.string,
  orderState: PropTypes.oneOf(["new", "updated", "cancelled", "completed", "ongoing"]),
  type: PropTypes.oneOf(["load", "unload"]).isRequired,
  routeId: PropTypes.string,
  onAction: PropTypes.func.isRequired,
  isOnline: PropTypes.bool,
  newWaybillNumber: PropTypes.string,
  waybillData: PropTypes.shape(WaybillDataShape),
};

export default withApollo(DestinationFooter);
