import 'react-responsive-modal/styles.css';

import { Formik, Form } from 'formik';
import Moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';

import { getExceptionsQuery } from '../../../../api/graphql/getExceptions';
import { getOrderQuery } from '../../../../api/graphql/getOrder';
import { getWaybillDataQuery } from '../../../../api/graphql/getWaybillData';
import { updateWaybillDataMutation } from '../../../../api/graphql/updateWaybillData';
import { updateMultipleWaybillDataMutation } from '../../../../api/graphql/updateMultipleWaybillData';
import fetchTransactionInputs from '../../../../utils/fetchTransactionInputs';
import Button from '../../../input/Button';
import Panel from '../../../layout/Panel';
import { getWaybillSignatureWrapperFunction, isWaybillSigned } from '../../utils/waybills';
import { WaybillDataShape } from '../shapes';
import WaybillExceptions from './WaybillExceptions';
import WaybillOrderInformation from './WaybillOrderInformation';
import WaybillOrderTransactionsSummary from './WaybillOrderTransactionsSummary';
import WaybillSignature from './WaybillSignature';
import styles from './WaybillSignatureModal.module.scss';
import NameClarificationInput from './NameClarificationInput';

const getExceptions = async (appSyncClient, routeId, orderId) => {
  if (!routeId || !orderId) {
    console.log('need routeId and orderId!');
    return undefined;
  }

  try {
    const response = await appSyncClient.query({
      query: getExceptionsQuery,
      variables: { routeId, orderId },
      fetchPolicy: 'network-only'
    });
    return response.data.getExceptions;
  } catch (error) {
    console.log('Query error', JSON.stringify(error, null, 2));
  }
};

const updateWaybillData = async (client, waybillSignatureInput, relatedOrders, useSignatureForEveryOrder) => {
  let mutationResponse = [];
  
  if (useSignatureForEveryOrder) {
    try {
      const waybillSignature = {
        ...waybillSignatureInput,
        orderIds: relatedOrders,
      }
      const response = await client.mutate({
        mutation: updateMultipleWaybillDataMutation,
        variables: { waybillSignature }
      });
      mutationResponse.push(...response.data.updateMultipleWaybillData);
    } catch (error) {
      console.log('Mutation error', JSON.stringify(error, null, 2));
      return mutation
    }
  } else {
    try {
      const response = await client.mutate({
        mutation: updateWaybillDataMutation,
        variables: { waybillSignature: waybillSignatureInput }
      });
      mutationResponse.push(response.data.updateWaybillData);
    } catch (error) {
      console.log('Mutation error', JSON.stringify(error, null, 2));
      return mutationResponse;
    }
  }

  return mutationResponse;
};

const getSignatureFromCache = (appSyncClient, routeId, orderId) => {
  let signaturesData = null;
  try {
    const res = appSyncClient.readQuery({
      query: getWaybillDataQuery,
      variables: {
        routeId: routeId,
        orderInfoComposite: orderId,
      }
    });
    signaturesData = res?.getWaybillData;
  } catch (error) {
    console.log('Failed fetching signature from cache', JSON.stringify(error, null, 2));
  }
  return signaturesData;
};

const getOrderFromCache = (appSyncClient, orderId) => {
  try {
    const response = appSyncClient.readQuery({
      query: getOrderQuery,
      variables: { orderId },
    });
    return response.getOrder;
  } catch (error) {
    console.log('Failed fetching order from cache', JSON.stringify(error, null, 2));
    throw error;
  }
};

const WaybillSignatureModal = ({
  apolloClient,
  signed,
  onSignaturesChange,
  type,
  waybillData,
  relatedOrders,
  actualPickupStartTime,
  actualPickupEndTime,
  actualUnloadStartTime,
  actualUnloadEndTime,
}) => {
  const [open, setOpen] = useState(false);
  const [showSignatureSuccess, setShowSignatureSuccess] = useState(false);
  const [showSignatureFail, setShowSignatureFail] = useState(false);

  const [driverNameClarification, setDriverNameClarification] = useState('');
  const [driverSignatureDataURL, setDriverSignatureDataURL] = useState(undefined);
  const [driverTimestamp, setDriverTimestamp] = useState(undefined);
  const [driverNameClarificationStyle, setDriverNameClarificationStyle] = useState({});
  const [driverNameClarificationError, setDriverNameClarificationError] = useState('');

  const [senderNameClarification, setSenderNameClarification] = useState('');
  const [senderSignatureDataURL, setSenderSignatureDataURL] = useState(undefined);
  const [senderTimestamp, setSenderTimestamp] = useState(undefined);
  const [senderSignatureNotAvailable, setSenderSignatureNotAvailable] = useState(false);
  const [senderNameClarificationStyle, setSenderNameClarificationStyle] = useState({});
  const [senderNameClarificationError, setSenderNameClarificationError] = useState('');

  const [receiverNameClarification, setReceiverNameClarification] = useState('');
  const [receiverSignatureDataURL, setReceiverSignatureDataURL] = useState(undefined);
  const [receiverTimestamp, setReceiverTimestamp] = useState(undefined);
  const [receiverSignatureNotAvailable, setReceiverSignatureNotAvailable] = useState(false);
  const [receiverNameClarificationStyle, setReceiverNameClarificationStyle] = useState({});
  const [receiverNameClarificationError, setReceiverNameClarificationError] = useState('');

  const [relatedSignatures, setRelatedSignatures] = useState([]);
  const [selectedSignature, setSelectedSignature] = useState(undefined);
  const [relatedSignaturesStatus, setRelatedSignaturesStatus] = useState('none'); // loading, none, succeeded, error

  const [useSignatureForEveryOrder, setUseSignatureForEveryOrder] = useState(false);

  const [exceptions, setExceptions] = useState([]);

  useEffect(async () => {
    const wayBillSignatures = await getWaybillSignatureWrapperFunction(apolloClient, waybillData.routeId, waybillData.orderId);
    const existingSignaturePresent = wayBillSignatures?.driverNameClarification;
    if (existingSignaturePresent) {
      setDriverNameClarification(wayBillSignatures.driverNameClarification ?? '');
      setDriverSignatureDataURL(wayBillSignatures.driverSignature);
      setDriverTimestamp(wayBillSignatures.driverTimestamp);
      setSenderNameClarification(wayBillSignatures.senderNameClarification ?? '');
      setSenderSignatureDataURL(wayBillSignatures.senderSignature);
      setSenderTimestamp(wayBillSignatures.senderTimestamp);
      setSenderSignatureNotAvailable(!!wayBillSignatures.senderSignatureNotAvailable);
    }
    existingSignaturePresent && onSignaturesChange(wayBillSignatures, [ wayBillSignatures ], type, waybillData.transactions);
  }, [actualPickupStartTime, actualPickupEndTime, actualUnloadStartTime, actualUnloadEndTime]);
  // reload signatures if timestamps change

  useEffect(() => {
    if (relatedOrders?.length > 0 && relatedSignaturesStatus === 'none') {
      setRelatedSignaturesStatus('loading');
      try {
        const ordersData = relatedOrders.map((orderId) => getOrderFromCache(apolloClient, orderId));

        const signatures = ordersData.map(order => {
          const routeId = order.rows[0].transactions[0].routeId;
          let waybillNum = order.rows[0].transactions[0].waybillNum;

          if (!waybillNum) {
            const transactionId = type === 'load' ? order.rows[0].transactions[0].pickupTransactionId : order.rows[0].transactions[0].unloadTransactionId;
            const transactionLoadInputs = fetchTransactionInputs();
            waybillNum = transactionLoadInputs[transactionId]?.waybill;

            if (!waybillNum) {
              return null;
            }
          }

          return getSignatureFromCache(apolloClient, routeId, order.id);
        }).filter(signature => signature !== null);

        const signaturesForType = signatures.filter(signature => {
          if (type === 'load') {
            return true
          } else {
            return signature.receiverSignature || signature.receiverSignatureNotAvailable
          }
        })

        setRelatedSignatures(signaturesForType);
        if (signaturesForType.length > 0) {
          const signature = signaturesForType[0];
          setSelectedSignature(signature);
          if (signature.senderSignatureNotAvailable) {
            setSenderSignatureNotAvailable(true);
          }
          if (signature.receiverSignatureNotAvailable) {
            setReceiverSignatureNotAvailable(true);
          }
        }
        setRelatedSignaturesStatus('succeeded');
      } catch (error) {
        console.log('error fetching related signatures', error)
        setRelatedSignaturesStatus('error');
      }
    }
  }, [relatedOrders]);

  useEffect(async () => {
    if (exceptions.length === 0) {
      const exceptions = await getExceptions(apolloClient, waybillData.routeId, waybillData.orderId);
      setExceptions(exceptions);
    }
  }, []);

  /**
   * Checks whether all required fields were filled, updates styles based on result and returns the result
   */
  const validateSignatureData = (isOngoingLoad, isOngoingUnload, signatureData) => {
    const {
      driverNameClarification,
      driverSignature,
      driverTimestamp,
      senderNameClarification,
      senderSignature,
      senderTimestamp,
      receiverNameClarification,
      receiverSignature,
      receiverTimestamp,
      senderSignatureNotAvailable,
      receiverSignatureNotAvailable,
    } = signatureData;

    const invalidValues = [];

    if (isOngoingLoad) {
      !driverNameClarification && invalidValues.push('driverNameClarification');
      !driverSignature && invalidValues.push('driverSignature');
      !driverTimestamp && invalidValues.push('driverTimestamp');
    }

    if (isOngoingLoad && !senderSignatureNotAvailable) {
      !senderNameClarification && invalidValues.push('senderNameClarification');
      !senderSignature && invalidValues.push('senderSignature');
      !senderTimestamp && invalidValues.push('senderTimestamp');
    }

    if (isOngoingUnload && !receiverSignatureNotAvailable) {
      !receiverNameClarification && invalidValues.push('receiverNameClarification');
      !receiverSignature && invalidValues.push('receiverSignature');
      !receiverTimestamp && invalidValues.push('receiverTimestamp');
    }

    if (invalidValues.length > 0) {
      // we got some invalid values, so set some error styles and keep the modal open
      setDriverNameClarificationStyle({ border: '2px solid #f26161' });
      setSenderNameClarificationStyle({ border: '2px solid #f26161' });
      setReceiverNameClarificationStyle({ border: '2px solid #f26161' });

      invalidValues.includes('driverNameClarification') && setDriverNameClarificationError('Nimenselvennys puuttuu');
      invalidValues.includes('senderNameClarification') && setSenderNameClarificationError('Nimenselvennys puuttuu');
      invalidValues.includes('receiverNameClarification') && setReceiverNameClarificationError('Nimenselvennys puuttuu');

      return false;
    } else {
      // we got all the required values, so we clear up error styles and close the modal
      setDriverNameClarificationStyle({ border: '2px solid #f26161' });
      setSenderNameClarificationStyle({ border: '2px solid #f26161' });
      setReceiverNameClarificationStyle({ border: '2px solid #f26161' });
      setDriverNameClarificationError('');
      setSenderNameClarificationError('');
      setReceiverNameClarificationError('');
      setOpen(false);

      return true;
    }
  };

  const getSignatureDataFromState = (isOngoingLoad, isOngoingUnload) => {
    if (isOngoingLoad) {
      return {
        driverNameClarification,
        driverSignature: driverSignatureDataURL,
        driverTimestamp,
        senderNameClarification,
        senderSignature: senderSignatureDataURL,
        senderTimestamp,
        senderSignatureNotAvailable,
      };
    }

    if (isOngoingUnload) {
      return {
        receiverNameClarification,
        receiverSignature: receiverSignatureDataURL,
        receiverTimestamp,
        receiverSignatureNotAvailable,
      };
    }

    return {};
  };

  const createWaybillSignatureInput = (routeId, customerNum, orderId, signatureData) => {
    return {
      routeId: routeId, // key
      customerNumber: customerNum,
      orderId: orderId, // part of composite key
      ...signatureData,
      actualPickupStartTime,
      actualPickupEndTime,
      actualUnloadStartTime,
      actualUnloadEndTime,
    };
  };

  /**
   * - validate signature data
   * - save signature images
   */
  const onSave = async () => {
    const signatureData = getSignatureDataFromState(waybillData.isOngoingLoad, waybillData.isOngoingUnload);
    const isValid = validateSignatureData(waybillData.isOngoingLoad, waybillData.isOngoingUnload, signatureData);

    if (isValid) {
      const waybillSignatureInput = createWaybillSignatureInput(waybillData.routeId, waybillData.customerNum, waybillData.orderId, signatureData);

      const signaturesList = await updateWaybillData(apolloClient, waybillSignatureInput, relatedOrders, useSignatureForEveryOrder);
      if (!signaturesList) {
        console.log('Unable to modify signature data');
        setShowSignatureFail(true);
        return;
      }

      const thisSignature = signaturesList.find(signature => signature.orderId === waybillData.orderId);
      let combinedSignature = thisSignature;
      if (type === 'unload') {
        const existingWaybillSignature = await getWaybillSignatureWrapperFunction(apolloClient, waybillData.routeId, waybillData.orderId);
        combinedSignature = {
          ...Object.entries(existingWaybillSignature || {})
            .filter(([_, value]) => value !== null)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
          ...Object.entries(thisSignature || {})
            .filter(([_, value]) => value !== null)
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
        };
      }

      signaturesList && onSignaturesChange(combinedSignature, signaturesList, type, waybillData.transactions);
      const isFullySigned = isWaybillSigned(combinedSignature, type);

      if (isFullySigned) {
        setShowSignatureSuccess(true);
      }
    } else {
      console.log('Signature data failed validation');
    }
  };

  const handlePresetSignatureChange = (signature) => {
    const index = relatedSignatures.indexOf(signature);
    const nextIndex = index + 1 >= relatedSignatures.length ? 0 : index + 1;
    setSelectedSignature(relatedSignatures[nextIndex]);
    if (relatedSignatures[nextIndex].senderSignatureNotAvailable) {
      setSenderSignatureNotAvailable(true);
    }
    if (relatedSignatures[nextIndex].receiverSignatureNotAvailable) {
      setReceiverSignatureNotAvailable(true);
    }
  };

  const isValidWaybillNumber = (value) => {
    if (!value || value.trim() === '') {
      return false;
    }

    const match = value.match(/([A-Za-z0-9\-_/]+)$/gi);
    if (match === null || match[0] !== value) {
      return false;
    }

    return value && value.toString().length > 0;
  };

  const isSignButtonDisabled = () => {
    if (signed) {
      return true;
    }

    const allWaybillNumbersValid = waybillData?.rows.every(row =>
      row.transactions.every(transaction => {
        const transactionLoadInputs = fetchTransactionInputs();
        const transactionId = transaction.pickupTransactionId;
        // Get the waybill number either from transactionLoadInputs or from the transaction itself
        const waybillNumber = transactionLoadInputs[transactionId]?.waybill ?? transaction.waybillNum;
        // Validate the waybill number
        return isValidWaybillNumber(waybillNumber);
      })
    );

    return !allWaybillNumbersValid;
  };

  // Get common information from first order row transaction
  const commonInfo = waybillData?.rows[0]?.transactions[0];

  return (
    <Formik
      initialValues={{}}
      onSubmit={onSave}
    >
      {formik =>{
        const { isSubmitting } = formik;

        return (
          <>
            <div className={styles.fields}>
              <Button
                onClick={() => setOpen(true)}
                disabled={isSignButtonDisabled()}
                isLoading={isSubmitting}
              >
                {signed ? 'Allekirjoitettu' : 'Allekirjoita'}
              </Button>

              <Modal open={open} onClose={() => setOpen(false)}>
                <Form>
                  <WaybillOrderTransactionsSummary numRows={waybillData?.transactions.length} />
                  <WaybillOrderInformation waybillData={waybillData} commonInfo={commonInfo} />
                  <WaybillExceptions exceptions={exceptions} routeId={waybillData?.routeId} orderId={waybillData?.orderId} />

                  {waybillData?.isOngoingLoad && (
                    <Panel className={styles.panel}>
                      <b>Kuljettajan allekirjoitus</b>
                      <WaybillSignature presetSignature={selectedSignature?.driverSignature} setSignatureData={(dataURL, timestamp) => {
                        setDriverSignatureDataURL(dataURL);
                        setDriverTimestamp(timestamp);
                      }} />

                      <NameClarificationInput
                        inputName="driverNameClarification"
                        selectedSignature={selectedSignature}
                        onChange={(value) => setDriverNameClarification(value)}
                        extraStyles={driverNameClarificationStyle}
                        error={driverNameClarificationError}
                      />
                    </Panel>
                  )}

                  {waybillData?.isOngoingLoad && (
                    <Panel>
                      <div className={`${styles.panel} ${senderSignatureNotAvailable ? styles.disabledPanel : ''}`}>
                        <b>Lähettäjän allekirjoitus</b>
                        <WaybillSignature presetSignature={selectedSignature?.senderSignature} setSignatureData={(dataURL, timestamp) => {
                          setSenderSignatureDataURL(dataURL);
                          setSenderTimestamp(timestamp);
                        }} />

                        <NameClarificationInput
                          inputName="senderNameClarification"
                          selectedSignature={selectedSignature}
                          onChange={(value) => setSenderNameClarification(value)}
                          extraStyles={senderNameClarificationStyle}
                          error={senderNameClarificationError}
                        />
                      </div>

                      <div>
                        <input type="checkbox" id="senderSignatureNotAvailable" name="senderSignatureNotAvailable"
                          checked={senderSignatureNotAvailable}
                          onChange={(event) => {
                            setSenderSignatureNotAvailable(event.target.checked)
                            setSenderTimestamp(Moment().format('DD.MM.YYYY HH:mm'));
                          }}
                          />
                        <label htmlFor="senderSignatureNotAvailable">Allekirjoitusta ei saatavilla</label>
                      </div>
                    </Panel>
                  )}

                  {waybillData?.isOngoingUnload && (
                    <Panel>
                      <div className={`${styles.panel} ${receiverSignatureNotAvailable ? styles.disabledPanel : ''}`}>
                        <b>Vastaanottajan allekirjoitus</b>
                        <WaybillSignature presetSignature={selectedSignature?.receiverSignature} setSignatureData={(dataURL, timestamp) => {
                          setReceiverSignatureDataURL(dataURL);
                          setReceiverTimestamp(timestamp);
                        }} />

                        <NameClarificationInput
                          inputName="receiverNameClarification"
                          selectedSignature={selectedSignature}
                          onChange={(value) => setReceiverNameClarification(value)}
                          extraStyles={receiverNameClarificationStyle}
                          error={receiverNameClarificationError}
                        />
                      </div>

                      <div>
                        <input type="checkbox" id="receiverSignatureNotAvailable" name="receiverSignatureNotAvailable"
                          checked={receiverSignatureNotAvailable}
                          onChange={(event) => {
                            setReceiverSignatureNotAvailable(event.target.checked);
                            setReceiverTimestamp(Moment().format('DD.MM.YYYY HH:mm'));
                          }}
                        />
                        <label htmlFor="receiverSignatureNotAvailable">Allekirjoitusta ei saatavilla</label>
                      </div>
                    </Panel>
                  )}

                  {relatedOrders.length > 1 && (
                    <Panel className={styles.panel}>
                      <div className={styles.consolidatedSignature}>
                        <label htmlFor="useSignatureForEveryOrder"><b>Käytä allekirjoituksia kaikilla käyntipaikan tilauksilla</b></label>
                        <input type="checkbox" id="useSignatureForEveryOrder" name="useSignatureForEveryOrder"
                          checked={useSignatureForEveryOrder}
                          onChange={(event) => {
                            setUseSignatureForEveryOrder(event.target.checked);
                          }}
                        />
                      </div>
                    </Panel>
                  )}

                  <div>
                    <Button type="submit">
                      Tallenna
                    </Button>
                    <Button onClick={() => setOpen(false)}>
                      Sulje
                    </Button>
                    {relatedSignatures.length > 1 && (
                      <Button onClick={() => handlePresetSignatureChange(selectedSignature)}>
                        Vaihda
                      </Button>
                    )}
                  </div>
                </Form>
              </Modal>
            </div>

            <Modal open={showSignatureSuccess} onClose={() => setShowSignatureSuccess(false)}>
              <div>
                <br/>
                <span>Rahtikirja allekirjoitettu!</span>
              </div>
              <Button onClick={() => setShowSignatureSuccess(false)}>
                OK
              </Button>
            </Modal>

            <Modal open={showSignatureFail} onClose={() => setShowSignatureFail(false)}>
              <div>
                <br/>
                <span>Rahtikirjan allekirjoittaminen epäonnistui!</span>
              </div>
              <Button onClick={() => setShowSignatureFail(false)}>
                OK
              </Button>
            </Modal>
          </>
        )
      }}

    </Formik>
  );
};

WaybillSignatureModal.propTypes = {
  apolloClient: PropTypes.object.isRequired,
  signed: PropTypes.bool.isRequired,
  onSignaturesChange: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['load', 'unload']).isRequired,
  waybillData: PropTypes.shape(WaybillDataShape).isRequired,
  relatedOrders: PropTypes.array,
  actualPickupStartTime: PropTypes.string,
  actualPickupEndTime: PropTypes.string,
  actualUnloadStartTime: PropTypes.string,
  actualUnloadEndTime: PropTypes.string,
};

export default WaybillSignatureModal;
