import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import get from 'lodash/get';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

import { Formik, Form } from "formik";
import { TextField } from "formik-mui";

import Field from "../input/Field";
import Button from "../input/Button";
import MultiSelectionButton from "../input/MultiSelectionButton";

import getTimeStamp from "../../utils/getDateTime";

import styles from "./ExceptionForm.module.scss";

/* Exceptions categories */
import SecurityExceptionInfo from "./SecurityExceptionInfo.json";


const InputGroup = ({
  value,
  error,
  touched,
  id,
  label,
  children
}) => {
  return (
    <div className={styles.inputGroup}>
      {/*touched && <ErrorMessage error={error} />*/}
      <legend>{label}</legend>
      <div className={styles.inputs}>
        {children}
      </div>
    </div>
  );
};

const FormFieldError = ({children}) => {
  return (
    <div className={styles.formFieldError}>
      {children}
    </div>
  );
};

const FormFieldStatus = ({valid, children}) => {
  return (
    <div className={styles.formFieldStatus}>
      {children}
    </div>
  );
};


// Input feedback
/*const InputFeedback = ({ error }) =>
  error ? <div className={styles.inputFeedback}>{error}</div> : null;*/

const SecurityException = (props) => {

  useEffect(() => {
    if (props.refreshStyles) {
      props.refreshStyles();
    }
  });

  const [activeStep, setActiveStep] = useState(0);
  const [selectedExCategory] = useState("TURVALLISUUSHAVAINTO");
  const [selectedExType, setSelectedExType] = useState(null);
  const [selectedExTypeCode, setSelectedExTypeCode] = useState(null);

  /*
    Validation options start as true to conform the validation schema
  */
  const [isTimeValid, setIsTimeValid] = useState(true);
  const [isDateValid, setIsDateValid] = useState(true);
  const [isAddressValid, setIsAddressValid] = useState(true);
  const [isInfoValid, setIsInfoValid] = useState(false);
  const [isMitigationValid, setIsMitigationValid] = useState(false);
  const [isObserverValid, setIsObserverValid] = useState(false);
  const [isOtherInfoValid, setIsOtherInfoValid] = useState(true);

  const [exceptionInfo] = useState(SecurityExceptionInfo);

  const steps = getSteps();

  function getSteps() {
    return ['Turvallisuushavainto', 'Lisätiedot'];
  }

  function handleNext() {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }

  function handleBack() {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }

  function handleCancel() {
    window.history.back();
  }

  function validateInput(values) {
    /* First check user input is valid */
    validateDate(values.detailExDateDay, values.detailExDateMonth, values.detailExDateYear);
    validateTime(values.detailExTimeHour, values.detailExTimeMin);
    validateAddress(values.detailExPlace);
    validateText(values.detailExInfo, setIsInfoValid, 500, 1);
    validateText(values.detailExMitigation, setIsMitigationValid, 500, 1);
    validateText(values.detailExObserver, setIsObserverValid, 50, 1);
    validateText(values.detailExOtherInfo, setIsOtherInfoValid, 500);
  }

  function validateForm() {
    /* Check all required fields are filled & valid */
    return !!(selectedExCategory &&
        selectedExType &&
        isTimeValid &&
        isDateValid &&
        isAddressValid &&
        isInfoValid &&
        isMitigationValid &&
        isObserverValid &&
        isOtherInfoValid);
  }

  function validateTime(hour, min) {
    setIsTimeValid((parseInt(hour) >= 0 && parseInt(hour) <= 23 && parseInt(min) >= 0 && parseInt(min) <= 59));
  }

  // Date must be dd.mm.yyyy
  function validateDate(day, month, year) {
    setIsDateValid((moment(`${day}.${month}.${year}`, 'DD.MM.YYYY', true).isValid()));
  }

  function validateAddress(address) {
    setIsAddressValid((address.length > 0 && address.length <= 100));
  }

  function validateText(text, setIsValid, max = 100, min = 0) {
    setIsValid((text.length >= min && text.length <= max));
  }

  function handleDateTimeInput(e, maxLength = 2) {
    // Format input:
    // 1. Remove prefixing zero
    // 2. Ignore non-numeric input
    // 3a. Enforce maximum length
    // 3b. Add prefixing zero (if required)

    // 1 & 2
    let formattedValue = e.target.value
      .replace(/^0+/, '')
      .replace(/[^0-9]/g, '');
    // 3 OR 4
    if (formattedValue.length > maxLength) {
      formattedValue = formattedValue.substring(0, maxLength);
    } else {
      while (formattedValue.length < maxLength) {
        formattedValue = "0" + formattedValue;
      }
    }
    // Update value
    e.target.value = formattedValue;
  }

  function handleTextInput(e, maxLength = 100) {
    // Block any data entry that is over the limit
    e.target.value = e.target.value.substring(0, maxLength);
  }

  /*
    Multi select button handling
  */
  /*function setExCategory(id) {
    setSelectedExCategory(id);
    setSelectedExTypeCode(null); // If the exception ID has changed, reset the category selection
    handleNext();
  }*/

  function setExType(id, code) {
    setSelectedExType(id);
    setSelectedExTypeCode(code);
    handleNext();
  }

  /*
    The exception logging form is build around a Material UI stepper component
    Multiple choice steps will only move to the next step when a category/type is select
    The final form page (extra details) contains the form submit button
  */
  function getStepContent(stepIndex, values, errors, touched) {

    switch (stepIndex) {
      case 0:
        return (
          <InputGroup
            id="selectedExType"
            label={`Valitse turvallisuushavainto poikkeama`}
            value={values.selectedExType}
            error={errors.selectedExType}
            touched={touched.selectedExType}
          >
            {Object.keys(exceptionInfo[selectedExCategory]["types"]).map((exTypeId) => {
              const exTypeLabel = exceptionInfo[selectedExCategory]["types"][exTypeId]["label"];
              const exTypeCode = exceptionInfo[selectedExCategory]["types"][exTypeId]["code"];
              return (
                <MultiSelectionButton
                  key={exTypeLabel}
                  name="selectedExCategory"
                  checked={exTypeId === selectedExType}
                  id={exTypeId}
                  onClick={() => setExType(exTypeId, exTypeCode)}
                  appearance="secondary"
                >
                  {exTypeLabel}
                </MultiSelectionButton>
              );
            })}
          </InputGroup>
        );

      case 1:
        return (
          <React.Fragment>
            <InputGroup
              id="fieldGroupExDetails"
              label={`${selectedExType}, ${selectedExCategory} poikkeama lisätiedot`}
              value={values.fieldGroupExDetails}
              error={errors.fieldGroupExDetails}
              touched={touched.fieldGroupExDetails}
            >
              <div className={styles.timeFieldWrapper}>
                <label htmlFor="detailExTime" className={styles.fieldlabel}>
                  Tapahtuma-aika
                </label>
                <div id="detailExTime" className={`${styles.dateFieldContainer} ${(isTimeValid) ? "" : styles.invalid}`}>
                  <Field
                    id="detailExTimeHour"
                    name="detailExTimeHour"
                    disabled={false}
                    className={styles.dateTimeFieldShort}
                    component={TextField}
                    onInput={(e) => handleDateTimeInput(e, 2)}
                    InputProps={{ disableUnderline: true, maxLength: 2 }}
                  />
                  <span>:</span>
                  <Field
                    id="detailExTimeMin"
                    name="detailExTimeMin"
                    disabled={false}
                    className={styles.dateTimeFieldShort}
                    component={TextField}
                    onInput={(e) => handleDateTimeInput(e, 2)}
                    InputProps={{ disableUnderline: true, maxLength: 2 }}
                  />
                </div>
                {!isTimeValid &&
                  <FormFieldError>
                    Anna kellonaika muodossa tt:mm (esim. 12:30)
                  </FormFieldError>
                }
              </div>
              <div className={styles.dateTimeFieldSpacer}></div>
              <div className={styles.dateFieldWrapper}>
                <label htmlFor="detailExDate" className={styles.fieldlabel}>
                  Tapahtuma-päivämäärä
                </label>
                <div id="detailExDate" className={`${styles.dateFieldContainer} ${(isDateValid) ? "" : styles.invalid}`}>
                  <Field
                    id="detailExDateDay"
                    name="detailExDateDay"
                    disabled={false}
                    className={styles.dateTimeFieldShort}
                    component={TextField}
                    onInput={(e) => handleDateTimeInput(e, 2)}
                    InputProps={{ disableUnderline: true, maxLength: 2 }}
                  />
                  <span>.</span>
                  <Field
                    id="detailExDateMonth"
                    name="detailExDateMonth"
                    disabled={false}
                    className={styles.dateTimeFieldShort}
                    component={TextField}
                    onInput={(e) => handleDateTimeInput(e, 2)}
                    InputProps={{ disableUnderline: true, maxLength: 2 }}
                  />
                  <span>.</span>
                  <Field
                    id="detailExDateYear"
                    name="detailExDateYear"
                    disabled={false}
                    className={styles.dateTimeFieldLong}
                    component={TextField}
                    onInput={(e) => handleDateTimeInput(e, 4)}
                    InputProps={{ disableUnderline: true, maxLength: 4 }}
                  />
                </div>
                {!isDateValid &&
                  <FormFieldError>
                    Anna päivämäärä muodossa pp.kk.vvvv (esim. 24.05.2018)
                  </FormFieldError>
                }
              </div>

              <label htmlFor="detailExPlace" className={styles.fieldlabel}>
                Tapahtumapaikka
              </label>
              <Field
                id="detailExPlace"
                name="detailExPlace"
                disabled={false}
                className={`${styles.fieldmultiline} ${(isAddressValid) ? "" : styles.invalid}`}
                component={TextField}
                onInput={(e) => handleTextInput(e, 100)}
                InputProps={{ disableUnderline: true }}
              />
              <FormFieldStatus>
                Kentän pituus {values.detailExPlace.length}/100
              </FormFieldStatus>

              <label htmlFor="detailExInfo" className={styles.fieldlabel}>
                Mitä tapahtui tai olisi voinut tapahtua?
              </label>
              <Field
                id="detailExInfo"
                name="detailExInfo"
                disabled={false}
                className={`${styles.fieldmultiline} ${(isInfoValid) ? "" : styles.invalid}`}
                component={TextField}
                onInput={(e)=>handleTextInput(e,500)}
                multiline
                rows={2}
                InputProps={{ disableUnderline: true }}
              />
              <FormFieldStatus>
                Kentän pituus {values.detailExInfo.length}/500
              </FormFieldStatus>

              <label htmlFor="detailExMitigation" className={styles.fieldlabel}>
                Ehdotus toimenpiteeksi?
              </label>
              <Field
                id="detailExMitigation"
                name="detailExMitigation"
                disabled={false}
                className={`${styles.fieldmultiline} ${(isMitigationValid) ? "" : styles.invalid}`}
                component={TextField}
                onInput={(e) => handleTextInput(e, 500)}
                multiline
                rows={2}
                InputProps={{ disableUnderline: true }}
              />
              <FormFieldStatus>
                Kentän pituus {values.detailExMitigation.length}/500
              </FormFieldStatus>

              <label htmlFor="detailExObserver" className={styles.fieldlabel}>
                Havainnon tekijä
              </label>
              <Field
                id="detailExObserver"
                name="detailExObserver"
                disabled={false}
                className={`${styles.fieldmultiline} ${(isObserverValid) ? "" : styles.invalid}`}
                component={TextField}
                onInput={(e) => handleTextInput(e, 50)}
                InputProps={{ disableUnderline: true }}
              />
              <FormFieldStatus>
                Kentän pituus {values.detailExObserver.length}/50
              </FormFieldStatus>

              <label htmlFor="detailExOtherInfo" className={styles.fieldlabel}>
                Lisätietoja
              </label>
              <Field
                id="detailExOtherInfo"
                name="detailExOtherInfo"
                disabled={false}
                className={`${styles.fieldmultiline} ${(isOtherInfoValid) ? "" : styles.invalid}`}
                component={TextField}
                onInput={(e) => handleTextInput(e, 500)}
                multiline
                rows={2}
                InputProps={{ disableUnderline: true }}
              />
              <FormFieldStatus>
                Kentän pituus {values.detailExOtherInfo.length}/500
              </FormFieldStatus>

            </InputGroup>
          </React.Fragment>
        );

      default:
        return <span>Error: Exception step not found! </span>;
    }
  }

  function onSubmit(exceptionData, actions) {
    if (!validateForm()) {
      return actions.setSubmitting(false);
    }
    props.onSubmit({
      category: selectedExCategory,
      type: selectedExType,
      code: selectedExTypeCode,
      riskInfo: exceptionData.detailExInfo,
      riskMitigation: exceptionData.detailExMitigation,
      riskObserver: exceptionData.detailExObserver,
      otherInfo: exceptionData.detailExOtherInfo,
      time: `${exceptionData.detailExDateYear}-${exceptionData.detailExDateMonth}-${exceptionData.detailExDateDay} ${exceptionData.detailExTimeHour}:${exceptionData.detailExTimeMin}`,
      place: exceptionData.detailExPlace
    });
  }

  const isFormValid = validateForm();
  const initTime = getTimeStamp();
  const address = get(props, "destination.address", "");
  const { isOnline } = props;
  return (
    <Formik
      initialValues={{
        detailExInfo: "",
        detailExMitigation: "",
        detailExObserver: "",
        detailExOtherInfo: "",
        detailExDateDay: initTime.day || "",
        detailExDateMonth: initTime.month || "",
        detailExDateYear: initTime.year || "",
        detailExTimeHour: initTime.hour || "",
        detailExTimeMin: initTime.minute || "",
        detailExPlace: address || "",
      }}
      //validationSchema={validationSchema}
      onSubmit={onSubmit}
      validate={validateInput}
      validateOnBlur={true}
      validateOnChange={true}
    >
      {formik => {
        const {
          isSubmitting,
          values,
          errors,
          touched} = formik;

        return (
          <React.Fragment>
            <Form className={styles.root}>
              <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map(label => (
                <Step
                  key={label}
                  classes={{
                    completed: styles.stepIconCompleted
                  }}>
                  <StepLabel
                    StepIconProps={{
                      classes: {
                        completed: styles.stepIconCompleted,
                        active: styles.stepIconActive,
                      }
                    }}
                  >{label}</StepLabel>
                </Step>
              ))}
              </Stepper>
              <div>
                <div>
                  <span className={styles.instructions}>{getStepContent(activeStep, values, errors, touched)}</span>
                  <div>
                  {(activeStep === 0) &&
                    <Button
                      disabled={activeStep !== 0}
                      onClick={handleCancel}
                      cancel="true"
                    >
                      Peruuta
                    </Button>
                  }
                  {activeStep !== 0 &&
                    <Button
                      disabled={activeStep === 0}
                      onClick={handleBack}
                      cancel="true"
                    >
                      Takaisin
                    </Button>
                  }
                  {activeStep === (steps.length - 1) &&
                    <Button
                      className={styles.submit}
                      type="submit"
                      isLoading={isSubmitting}
                      uppercase="uppercase"
                      disabled={isSubmitting || !isFormValid || !isOnline}
                      title={!isOnline ? "Ei internet yhteyttä" : null}
                    >
                      Lähetä
                    </Button>
                  }
                  </div>
                </div>
              </div>
              <br />
            </Form>
          </React.Fragment>
        )
      }}
    </Formik>
  );
}

SecurityException.propTypes = {
  destination: PropTypes.shape({
    destinationId: PropTypes.string,
    destinationType: PropTypes.string,
    destinationState: PropTypes.string,
    destinationAddress: PropTypes.string,
  }),
  isOnline: PropTypes.bool
};

export default SecurityException;
