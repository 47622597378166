import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import expandIcon from "../../../assets/expand-less.svg";
import styles from "./DestinationSummary.module.scss";
import Transactions from "../schedule/Transactions";

const ExpandIcon = () => <img src={expandIcon} alt="Suurenna/Pienennä" />;

const DestinationSummary = ({ destination, transitionDuration, onlyTransactions = false }) => (
  <Accordion 
    collapseprops={{timeout:parseInt(transitionDuration)}}
    square={true}
    className={`${styles.root} ${styles[destination.type]}`}
    defaultExpanded={onlyTransactions}
    onChange={()=>{
      // This is dirty and naughty.
      // The purpose is to trigger resizing of HereMaps component
      // to make it fit the available space.
      // const start = (new Date()).getTime();
      // function resize(){
      //   window.dispatchEvent(new Event('resize'));
      //   if((new Date()).getTime() - start < parseInt(transitionDuration) + 100){
      //     requestAnimationFrame(resize);
      //   }
      // }
      // resize();
      setTimeout(()=>window.dispatchEvent(new Event('resize')), parseInt(transitionDuration) + 100);
    }}
  >
    <AccordionSummary classes={{root: styles.accordionsummary}} expandIcon={<ExpandIcon/>}>
      <div className={`${styles.column} ${styles.flex}`}>
        <div className={styles.titleRow}>
          <div className={`${styles.type} ${styles[destination.type]}`}>
            {destination.type === "load" ? "Lastaus" : "Purku"}
          </div>
          <h2>{destination.name}</h2>
        </div>
      </div>
    </AccordionSummary>
    <AccordionDetails className={styles.details}>
      <div className={`${styles.column} ${styles.flex}`}>
        <div className={styles.contactDetails}>
          <div className={styles.location}>
            {(get(destination, "address", "") || "").trim()} {destination.zipcode}{" "}
            {(get(destination, "city", "") || "")
              .trim()
              .toLowerCase()
              .replace(/^\w/, c => c.toUpperCase())}
            {destination.addressAdditionalInfo &&
              `, ${destination.addressAdditionalInfo}`}
          </div>
          {(destination.contactPerson || destination.contactPhone) && (
            <div className={styles.contact}>
              {destination.contactPerson && <span>{`${destination.contactPerson}`}&nbsp;</span>} {(destination.contactPhone && destination.contactPhone.length > 6) && <a href={`tel:${destination.contactPhone}`}>{destination.contactPhone}</a>}
            </div>
          )}
        </div>
        <Transactions transactions={destination.transactions} />
      </div>
    </AccordionDetails>
  </Accordion>
);

DestinationSummary.propTypes = {
  destination: PropTypes.shape({
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
    addressAdditionalInfo: PropTypes.string,
    zipcode: PropTypes.string.isRequired,
    city: PropTypes.string,
    contactPerson: PropTypes.string,
    contactPhone: PropTypes.string,
    //additionalInfo: PropTypes.array,
    transactions: PropTypes.array,
  }).isRequired,
};

export default DestinationSummary;
