import gql from "graphql-tag";

export const getImageDownloadUrlQuery = gql`
  query getImageDownloadUrl(
    $routeId: Int!
    $orderId: Int!
    $waybillNum: String!
    $imageId: Int!
  ) {
    getImageDownloadUrl(
      waybillImageInput: {
        routeId: $routeId
        orderId: $orderId
        waybillNum: $waybillNum
        imageId: $imageId
      }
    ) {
      url
    }
  }
`;
