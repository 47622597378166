import { configureStore } from '@reduxjs/toolkit';

import waybillImagesReducer from './reducers/waybillImagesSlice';

const store = configureStore({
  reducer: {
    waybillImages: waybillImagesReducer,
  }
});

export default store;
