import { createSlice } from '@reduxjs/toolkit';

const waybillImagesSlice = createSlice({
  name: 'waybilImages',
  initialState: {
    uploading: false,
  },
  reducers: {
    setUploading: (state, { payload }) => {
      state.uploading = payload.uploading;
    },
  }
});

export const { setUploading } = waybillImagesSlice.actions;
export default waybillImagesSlice.reducer;