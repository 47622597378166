import retrieveLocalData from "./retrieveLocalData";
import storeLocalData from "./storeLocalData";
import parseNumber from "./parseNumber";

export default (
    transactionId,
    { waybill, amountLoaded, weightNoteNumberLoading, weightNoteNumberUnloading,
      container1Load, container2Load, container3Load, container4Load,
      container1Unload, container2Unload, container3Unload, container4Unload,
      senderNameClarification, senderTimestamp, senderSignatureNotAvailable,
      driverNameClarification, driverTimestamp,
      receiverNameClarification, receiverTimestamp, receiverSignatureNotAvailable,
      signed, signedOnLoad, signedOnUnload
    }
  ) => {
    if (!transactionId) {
      console.log("Cannot update transactionLoadInputs with null transaction key. Waybill ", waybill);
      return;
    }

    // Use cache as basis
    let transactionInputs = {
      ...retrieveLocalData("transactionLoadInputs")
    };

    // Get or initialize the transaction entry
    const transaction = transactionInputs[transactionId] || {};
    let updated = false;

    // Define fields with their processors
    const fields = {
      waybill,
      amountLoaded: amountLoaded !== undefined ? parseNumber(amountLoaded) : undefined,
      weightNoteNumberLoading,
      weightNoteNumberUnloading,
      container1Load,
      container2Load,
      container3Load,
      container4Load,
      container1Unload,
      container2Unload,
      container3Unload,
      container4Unload,
      senderNameClarification,
      senderTimestamp,
      senderSignatureNotAvailable,
      driverNameClarification,
      driverTimestamp,
      receiverNameClarification,
      receiverTimestamp,
      receiverSignatureNotAvailable,
      signed,
      signedOnLoad,
      signedOnUnload
    };

    //Overwrite values if new value is provided, otherwise leave as is
    // Update only defined fields
    Object.entries(fields).forEach(([key, value]) => {
      if (value !== undefined) {
        transaction[key] = value;
        updated = true;
      }
    });

    // Only modify the storage if something changed
    if (updated) {
      transactionInputs[transactionId] = transaction;
      storeLocalData("transactionLoadInputs", transactionInputs);
    }
};

