import { Link, navigate } from "@reach/router";
import find from "lodash/find";
import get from "lodash/get";
import head from "lodash/head";
import reduce from "lodash/reduce";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Mutation, withApollo } from "react-apollo";

import { fragmentOrder } from "../../api/graphql/fragments/order";
import { fragmentRoute } from "../../api/graphql/fragments/route";
import {logEvent} from "../../api/graphql/logEvent";
import { setTransactionStatusMutation } from "../../api/graphql/setTransactionStatus";
import DestinationFooter from "../../components/routes/destination/DestinationFooter";
import { WaybillDataShape } from "../../components/routes/order/shapes";
import {
  getWaybillSignatureWrapperFunction,
  isWaybillSigned,
  isWaybillSignedOnLoad,
  isWaybillSignedOnUnload,
  resolveWaybillNumberAndSignatures
} from "../../components/routes/utils/waybills";
import fetchTransactionInputs from "../../utils/fetchTransactionInputs";
import getTimeStamp from "../../utils/getTimeStamp";
import getUniqueProps from "../../utils/getUniqueProps";
import reformatTimeStamp from "../../utils/reformatTimeStamp";
import amountValidation from "../data/utils/amountValidation";
import styles from "./DestinationWrapper.module.scss";
import updateDestinationOrderCustomer from "../../utils/updateDestinationOrderCustomer";
import fetchDestinationOrderCustomer from "../../utils/fetchDestinationOrderCustomer";
import updateTransactionInput from "../../utils/updateTransactionInput";

const logBackend = async (appSyncClient, message) => {
  try {
    await appSyncClient.mutate({
      mutation: logEvent,
      variables: {
        message: message
      },
    });
  } catch (error) {
    console.log("Error logging", error);
  }
};

const getNextStatus = (type, ata) => {
  if (type === "load") {
    return !ata ? "beginLoading" : "finishLoading";
  }
  return !ata ? "beginUnloading" : "finishUnloading";
};

export const handleFinishState = (destination, client, waybillData, onInvalidTransactions) => {
  const nextStatus = getNextStatus(destination.type, destination.ata);
  logBackend(client, `Next status for order: ${waybillData.orderId} is: ${JSON.stringify(nextStatus, null, 2)}, destination.type: ${destination.type}, destination.ata: ${destination.ata}`);

  if (nextStatus === "finishLoading" || nextStatus === "finishUnloading") {
    const invalidTransactions = getInvalidTransactions(destination, waybillData.orderId, client);

    if (invalidTransactions.length > 0) {
      navigate(`/routes/schedule/upcoming/destination/${destination.id}`);
      onInvalidTransactions(invalidTransactions);
      return false;
    }

    if (nextStatus === "finishLoading") {
      navigate(`/routes/schedule/upcoming/destination/${destination.id}`);
    }
  }
  return true;
};

const getInvalidTransactions = (destination, orderId, client) => {
  const transactionLoadInputs = fetchTransactionInputs();
  const transactions = destination.transactions;
  const errors = [];
  const invalidTransactions = transactions.filter((transaction) => {
    const transactionLoadInput = transactionLoadInputs[transaction.transactionId];

    const amountValid =
      transactionLoadInput && !amountValidation(transactionLoadInput.amountLoaded, transaction.orderedUnit);

    if (destination.type === "unload") {
      const inValidAmount = !transactionLoadInput || !amountValid;
      if (inValidAmount) {
        errors.push({ transactionId: transaction.transactionId, error: "Invalid amount" });
        return inValidAmount;
      }
      // if amount is valid, check if the transaction is signed
    }

    const isSigned = transactionLoadInput &&
      ((destination.type === "load" && !!transactionLoadInput.signedOnLoad) || (destination.type === "unload" && !!transactionLoadInput.signedOnUnload));

    if (!isSigned) {
      errors.push({ transactionId: transaction.transactionId, error: "Not signed" });
    }

    return !transactionLoadInput || !transactionLoadInput.waybill || !amountValid || !isSigned;
  });

  if (invalidTransactions.length > 0) {
    logBackend(
      client,
      `Invalid transactions for order: ${orderId}, invalid transactions: ${JSON.stringify(invalidTransactions, null, 2)} ` +
      `with errors ${JSON.stringify(errors, null, 2)}, transactionLoadInputs: ${JSON.stringify(transactionLoadInputs, null, 2)}`
    );
  }
  return invalidTransactions;
};

const buildMutationVariables = (destination) => {
  const nextStatus = getNextStatus(destination.type, destination.ata);
  const transactionLoadInputs = fetchTransactionInputs();
  return {
    routeId: destination.routeId,
    status: nextStatus,
    timestamp: getTimeStamp(),
    transactions: destination.transactions.map((transaction) => {
      const { transactionId, orderedUnit } = transaction;
      const transactionLoadInput = transactionLoadInputs[transactionId] || {
        amountLoaded: 0,
        waybill: "",
        weightNoteNumberLoading: "",
        weightNoteNumberUnloading: "",
        container1Load: "",
        container2Load: "",
        container3Load: "",
        container4Load: "",
        container1Unload: "",
        container2Unload: "",
        container3Unload: "",
        container4Unload: "",
      };

      const {
        amountLoaded,
        waybill,
        weightNoteNumberLoading,
        weightNoteNumberUnloading,
        container1Load,
        container2Load,
        container3Load,
        container4Load,
        container1Unload,
        container2Unload,
        container3Unload,
        container4Unload,
      } = transactionLoadInput;

      return {
        transactionId,
        loadUnit: orderedUnit,
        loadAmount:
          // loading starts, no amount yet
          ["beginLoading", "beginUnloading"].includes(nextStatus)
            ? null
            : // loading finishes, driver input value
            ["finishLoading", "finishUnloading"].includes(nextStatus)
              ? amountLoaded
              : // loading is already done, actual load
              Math.abs(transaction.actualAmount),
        waybillNumber: waybill || (transaction.waybillNum ?? ""),
        weightNoteNumberLoading: weightNoteNumberLoading || "",
        weightNoteNumberUnloading: weightNoteNumberUnloading || "",
        container1Load: container1Load || "",
        container2Load: container2Load || "",
        container3Load: container3Load || "",
        container4Load: container4Load || "",
        container1Unload: container1Unload || "",
        container2Unload: container2Unload || "",
        container3Unload: container3Unload || "",
        container4Unload: container4Unload || "",
      };
    })
  };
};

const optimisticallyUpdateCache = (client, destinationId, transactionUpdateMutation) => {
  /*
      Perform an optimistic Cache update. This is done via Query fragraments
      as the 'Optimistic Response' provided by the mutation doesn't merge into
      the cache, but overwrites cache entries.

      Using the Query fragments, first look up the cached Route data, merge
      in the updated values and then write the cache. Second look up the Orders
      data, overwrite updated values and write the cache.

      Both Routes & Orders need updated, as these contain duplicated data returned
      by SISU and it appears both are used throughout the app :(

      Once the actual Query is fetched from the backend after the Mutation, it will
      overwrite any optimistically cached values

      This single function is basically everything that allows the app to work without
      a network connection.

    */

  /*
      1. get the cached Routes
    */
  const currentRouteCache = client.readFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`,
  }) || {};

  /*
      2. Fetch the relevant order numbers for the transactions
    */
  const orderNums = get(currentRouteCache, "destinations", []).map((dest) => {
    return get(dest, "transactions", []).map((transaction) => {
      // Check if the transaction ID matches a transaction that is being updated
      const isUpdatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
        return t.transactionId === transaction.transactionId;
      });
      return isUpdatedTransaction ? transaction.orderNum : null;
    });
  });

  /*
      3. Get all orders that need updated
    */
  const currentOrdersCache = orderNums.flat().filter((orderNum) => !!orderNum).map((orderNum) => {
    try {
      return client.readFragment({
        fragmentName: "Order",
        fragment: fragmentOrder,
        id: `Order:${orderNum}`,
      });
    } catch (err) {
      return {};
    }
  });

  const isStarting = ["beginLoading", "beginUnloading"].includes(transactionUpdateMutation.status);
  const isFinishing = ["finishLoading", "finishUnloading"].includes(transactionUpdateMutation.status);

  /*
      4. Create the updated cache for the Routes.
    */
  const optimisticResponseTimestamp = reformatTimeStamp(
    transactionUpdateMutation.timestamp,
    "YYYY-MM-DD HH:mm:ss",
    "DD.MM.YYYY HH:mm"
  );

  let optimisticRouteResp = {
    ...currentRouteCache,
    //status: this is set after all transactions have been updated
    destinations: currentRouteCache.destinations.map((cachedDest) => {
      const isDestInMutation = destinationId === cachedDest.id;
      return {
        ...cachedDest,
        ...(isDestInMutation && isStarting && { ata: optimisticResponseTimestamp }),
        ...(isDestInMutation && isFinishing && { atd: optimisticResponseTimestamp }),
        transactions: cachedDest.transactions.map((cachedTransaction) => {
          // Get cached ID, check if it is in the and merge, otherwise just return it
          const updatedTransaction = find(transactionUpdateMutation.transactions, (t) => {
            return t.transactionId === cachedTransaction.transactionId;
          });
          return {
            ...cachedTransaction,
            ...(updatedTransaction && {
              waybillNum: updatedTransaction.waybillNumber,
              actualAmount: updatedTransaction.loadAmount,
              actualUnit: updatedTransaction.loadUnit,
              ...(isStarting && { timeStarted: optimisticResponseTimestamp }),
              ...(isFinishing && { timeEnded: optimisticResponseTimestamp }),
            }),
          };
        }),
      };
    }),
  };

  /*
      5. After updating the transaction statuses, check if there are any
      remaining transactions that are not complete (A completed route has no pending transactions).

      Use this to set the Route status to either "upcoming" or "completed".
    */

  const isRouteIncomplete = reduce(
    optimisticRouteResp.destinations,
    (incompleteDests, destination) => {
      // Once one transaction is still pending (incompleteDests to true),
      // the whole route is still ongoing so don't bother with any further evaluations
      if (incompleteDests) {
        return incompleteDests;
      }
      // Next check if there are any incomplete transactions
      const isIncompleteTxns = reduce(
        get(destination, "transactions", []),
        (incompleteTxns, transaction) => {
          if (incompleteTxns) {
            return incompleteTxns; // Same as for destinations.
          }
          // Else, if timeEnded is defined, transaction is complete
          return !get(transaction, "timeEnded") ? true : false;
        },
        false
      );
      return isIncompleteTxns;
    },
    false
  );

  // Update the optimistic response with the correct status
  optimisticRouteResp.status = isRouteIncomplete ? "upcoming" : "completed";

  /*
      6. Build optimistic responses for the Orders
    */
  const optimisticOrderResps = currentOrdersCache.map((currentOrderCache) => {
    return {
      ...currentOrderCache,
      rows: get(currentOrderCache, "rows", []).map((orderRow) => {
        return {
          ...orderRow,
          transactions: get(orderRow, "transactions", []).map((orderRowTransaction) => {
            // Get cached ID, check if it is in the and merge, otherwise just return
            // the original transaction data

            // Order Row Transaction have the fun of two transaction IDs (pickup & unload)
            // so there is a need to match both
            const updatedPickupTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.pickupTransactionId;
            });
            const updatedUnloadTransaction = find(transactionUpdateMutation.transactions, (t) => {
              return t.transactionId === orderRowTransaction.unloadTransactionId;
            });
            return {
              ...orderRowTransaction,
              ...(updatedPickupTransaction && {
                waybillNum: updatedPickupTransaction.waybillNumber,
                actualAmount: updatedPickupTransaction.loadAmount,
                actualUnit: updatedPickupTransaction.loadUnit,
                ...(isStarting && { actualPickupStartTime: optimisticResponseTimestamp }),
                ...(isFinishing && { actualPickupEndTime: optimisticResponseTimestamp }),
              }),
              ...(updatedUnloadTransaction && {
                waybillNum: updatedUnloadTransaction.waybillNumber,
                actualAmount: updatedUnloadTransaction.loadAmount,
                actualUnit: updatedUnloadTransaction.loadUnit,
                ...(isStarting && { actualUnloadStartTime: optimisticResponseTimestamp }),
                ...(isFinishing && { actualUnloadEndTime: optimisticResponseTimestamp }),
              }),
            };
          }),
        };
      }),
    };
  });

  /*
      7. Perform writes to cache
    */

  client.writeFragment({
    fragmentName: "Route",
    fragment: fragmentRoute,
    id: `Route:${transactionUpdateMutation.routeId}`,
    data: optimisticRouteResp,
  });

  optimisticOrderResps.forEach((optimisticOrderResp) => {
    client.writeFragment({
      fragmentName: "Order",
      fragment: fragmentOrder,
      id: `Order:${optimisticOrderResp.orderNum}`,
      data: optimisticOrderResp,
    });
  });

  // Finally, update the transaction inputs that have been stored to the cache.

  return { optimisticRouteResp, optimisticOrderResps };
};

const updateActualTimes = (cacheUpdateResult, setActualPickupStartTime, setActualPickupEndTime, setActualUnloadStartTime, setActualUnloadEndTime) => {
  const updateOrder = cacheUpdateResult.optimisticOrderResps.filter(orderObject => orderObject.rows.length > 0)
    .map(o => o.rows).flat().map(row => row.transactions).flat();
  setActualPickupStartTime(updateOrder[0]?.actualPickupStartTime);
  setActualPickupEndTime(updateOrder[0]?.actualPickupEndTime);
  setActualUnloadStartTime(updateOrder[0]?.actualUnloadStartTime);
  setActualUnloadEndTime(updateOrder[0]?.actualUnloadEndTime);
};

const handleRedirect = (destination) => {
  const nextStatus = getNextStatus(destination.type, destination.ata);
  if (nextStatus === "beginLoading" || nextStatus === "beginUnloading") {
    let nextLocation = "";
    const destinationOrderIds = getUniqueProps(destination.transactions, "orderNum");
    if (destinationOrderIds.length === 1) {
      nextLocation = `/routes/schedule/upcoming/destination/${destination.id}/${head(destinationOrderIds)}`;
    } else {
      nextLocation = `/routes/schedule/upcoming/destination/${destination.id}`;
    }

    const currentLocation = window.location.pathname;
    if (currentLocation !== nextLocation) {
      navigate(nextLocation);
    }
  }
};

const handleSignatures = (signatures, type, transactions, setSigned) => {
  const signed = signatures ? isWaybillSigned(signatures, type) : false;

  setSigned(signed);

  // In multiple-order case, we need to filter signatures by order or waybill. Signatures input value may be null.
  if (signatures) {
    if (transactions && type === 'load') {
      transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction => {
        const transactionId = transaction.pickupTransactionId || transaction.transactionId;
        updateTransactionInput(transactionId, {
          "signedOnLoad": isWaybillSignedOnLoad(signatures),
        })
      });
    } else if (transactions && type === 'unload') {
      transactions.filter(transaction => transaction.orderNum === signatures.orderId).forEach(transaction => {
        const transactionId = transaction.unloadTransactionId || transaction.transactionId;
        updateTransactionInput(transactionId, {
          "signedOnUnload": isWaybillSignedOnUnload(signatures),
        })
      });
    }
  }
}

const DestinationWrapper = ({
  client,
  allowActions,
  allowExceptions,
  orderView,
  className,
  currentVehicle,
  destination,
  headerLinkTarget,
  headerLinkText,
  isOnline,
  newWaybillNumber,
  onInvalidTransactions,
  refreshStyles,
  setLoadingState,
  waybillData,
  children,
  setSignedWaybillNumber,
}) => {
  const [actualPickupStartTime, setActualPickupStartTime] = useState(null);
  const [actualPickupEndTime, setActualPickupEndTime] = useState(null);
  const [actualUnloadStartTime, setActualUnloadStartTime] = useState(null);
  const [actualUnloadEndTime, setActualUnloadEndTime] = useState(null);
  const [fetching, setFetching] = useState(false);
  
  const [signed, setSigned] = useState(false);
  const [waybillNumber, setWaybillNumber] = useState(newWaybillNumber);
  const [finalSubmitOngoing, setFinalSubmitOngoing] = useState(false);

  const headerText = `${destination.type === "load" ? "Lastaus" : "Purku"} – ${destination.name}`;

  useEffect(async () => {
    const fetchInitialData = async () => {
      setFetching(true);
      const result = await resolveWaybillNumberAndSignatures(orderView, waybillData, destination.type, client, destination.routeId);
      if (result.waybillNumber !== waybillNumber) {
        const destinationData = fetchDestinationOrderCustomer(destination.id)
        const updatedDestinationData = { ...destinationData, waybillNumber: result.waybillNumber };
        updateDestinationOrderCustomer(destination.id, updatedDestinationData);
        setWaybillNumber(result.waybillNumber);
      }
      if (result.signed !== signed) {
        setSigned(result.signed);
      }
      setFetching(false);
    };

    if (!fetching) {
      if (!orderView) {
        // if we are not in orderView then reset the waybill number
        setWaybillNumber(null);
      } else if (waybillData.rows[0].transactions[0].waybillNum) {
        // as a first option try to see if we already have the waybill number
        const num = waybillData.rows[0].transactions[0].waybillNum;
        setWaybillNumber(num);

        try {
          const signatures = await getWaybillSignatureWrapperFunction(client, destination.routeId, waybillData.orderId);

          if (signatures) {
            handleSignatures(signatures, destination.type, destination.transactions, setSigned);
          } else { // if we don't have the signatures, fallback to fetching them
            fetchInitialData();
          }
        } catch (error) {
          fetchInitialData();
        }
      } else {
        fetchInitialData();
      }
    }
  }, [orderView, waybillData, destination.type, destination.routeId]);

  useEffect(async () => {
    // Do not update waybill number if destination is unload, waybillNumber should not change on unload
    if (destination.type !== "unload" && newWaybillNumber !== waybillNumber) {
      setWaybillNumber(newWaybillNumber);
      const signatures = await getWaybillSignatureWrapperFunction(client, destination.routeId, waybillData.orderId);
      if (signatures) {
        handleSignatures(signatures, destination.type, destination.transactions, setSigned);
      }
    }
  }, [newWaybillNumber]);

  useEffect(() => {
    if (setSignedWaybillNumber) {
      setSignedWaybillNumber(signed);
    }
  }, [signed]);

  useEffect(() => {
    refreshStyles();
  }, [refreshStyles]);

  const isUnique = (value, index, array) => {
    return array.indexOf(value) === index;
  };

  const relatedOrders = destination.transactions
    .map((transaction) => transaction.orderNum)
    .filter(isUnique);

  return (
    <Mutation mutation={setTransactionStatusMutation} ignoreResults>
      {(setTransactionStatus, { error }) => (
        <div
          className={`${styles.wrapper} ${styles[className]} ${destination.state === "completed"
            ? styles.completed
            : destination.state === "ongoing"
              ? destination.type === "load"
                ? styles.loading
                : styles.unloading
              : null
            }`}
        >
          <header>
            {headerLinkTarget && (
              <Link to={headerLinkTarget}>{headerLinkText}</Link>
            )}
            <span>{headerText}</span>
          </header>
          <div className={`${styles.root}`}>
            <div className={styles.scroll}>{children(waybillNumber)}</div>
          </div>
          <DestinationFooter
            allowActions={allowActions}
            allowExceptions={allowExceptions}
            ata={destination.ata}
            atd={destination.atd}
            currentVehicle={currentVehicle}
            destinationId={destination.id}
            eta={destination.eta}
            etd={destination.etd}
            isOnline={isOnline}
            checkIfAbleToFinish={() => handleFinishState(destination, client, waybillData, onInvalidTransactions)}
            onAction={() => {
              if (error) {
                console.log(error);
              }
  
              if (!allowActions) {
                return;
              }
  
              setLoadingState(true);
  
              const ableToFinish = handleFinishState(destination, client, waybillData, onInvalidTransactions);
              if (!ableToFinish) {
                setLoadingState(false);
                return;
              }
  
              const variables = buildMutationVariables(destination);
              logBackend(client, `Sending mutation to SISU, order: ${waybillData.orderId}, mutation variables: ${JSON.stringify(variables, null, 2)}`);
  
              setTransactionStatus({ variables });
  
              setLoadingState(false);
  
              const cacheUpdateResult = optimisticallyUpdateCache(client, destination.id, variables);
              logBackend(client, `Optimistically updated cache for route containing order: ${waybillData.orderId}, cache result: ${JSON.stringify(cacheUpdateResult, null, 2)}`);
              updateActualTimes(cacheUpdateResult, setActualPickupStartTime, setActualPickupEndTime, setActualUnloadStartTime, setActualUnloadEndTime);
  
              handleRedirect(destination);
              return;
            }}
            orderView={orderView}
            relatedOrders={relatedOrders}
            orderState={destination.state}
            transactions
            type={destination.type}
            waybillData={waybillData}
            actualPickupStartTime={actualPickupStartTime}
            actualPickupEndTime={actualPickupEndTime}
            actualUnloadStartTime={actualUnloadStartTime}
            actualUnloadEndTime={actualUnloadEndTime}
            waybillNumber={waybillNumber}
            signed={signed}
            setSigned={setSigned}
            finalSubmitOngoing={finalSubmitOngoing}
            setFinalSubmitOngoing={setFinalSubmitOngoing}
            fetching={fetching}
          />
        </div>
      )}
    </Mutation>
  );
};

DestinationWrapper.propTypes = {
  allowActions: PropTypes.bool.isRequired,
  allowExceptions: PropTypes.bool,
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentVehicle: PropTypes.object,
  destination: PropTypes.shape({
    type: PropTypes.oneOf(["load", "unload"]).isRequired,
    name: PropTypes.string,
    state: PropTypes.oneOf([
      "new",
      "updated",
      "cancelled",
      "completed",
      "ongoing"
    ])
  }).isRequired,
  headerLinkTarget: PropTypes.string,
  headerLinkText: PropTypes.string,
  isOnline: PropTypes.bool,
  newWaybillNumber: PropTypes.string,
  onInvalidTransactions: PropTypes.func,
  orderView: PropTypes.bool,
  refreshStyles: PropTypes.func,
  relatedOrders: PropTypes.array,
  setLoadingState: PropTypes.func,
  waybillData: PropTypes.shape(WaybillDataShape),
  setSignedWaybillNumber: PropTypes.func
};

DestinationWrapper.defaultPropTypes = {
  headerLinkTarget: "../",
  headerLinkText: "Takaisin",
  className: "",
  isOnline: false,
};

export default withApollo(DestinationWrapper);
